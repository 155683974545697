import { Injectable } from '@angular/core';
import { HttpRequest, HttpResponse, HttpHandler, HttpEvent, HttpInterceptor, HTTP_INTERCEPTORS } from '@angular/common/http';
import { Observable, of, throwError } from 'rxjs';
import {delay, mergeMap, materialize, dematerialize, tap} from 'rxjs/operators';
import {ToastrService} from "ngx-toastr";
import {environment} from "../../../environments/environment";
import {Router} from "@angular/router";

const users: User[] = [{ id: 1, username: 'test', password: 'test', firstName: 'Test', lastName: 'User' }];

@Injectable()
export class AuthInterceptor implements HttpInterceptor {
  constructor(private toast:ToastrService,private router: Router) {
  }
  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    const { url, method, headers, body } = request;

    // wrap in delayed observable to simulate server api call
    return of(null)
      .pipe(mergeMap(handleRoute))
      .pipe(materialize()) // call materialize and dematerialize to ensure delay even if an error is thrown (https://github.com/Reactive-Extensions/RxJS/issues/648)
      .pipe(delay(500))
      .pipe(dematerialize())
      .pipe(tap((event: HttpEvent<any>) => {
      if (event instanceof HttpResponse) {
      //  event = event.clone({body: {"rsponse" : event.body}});
        if(event.body.code == 504 || (event.body.code == 400 && !event.body.data)  ){
          this.toast.warning(event.body.msg || "Token expirer. Vous devez vous reconnecter de nouveau","Erreur");
          this.router.navigate(['logout']);
         // window.location.href = "/logout"
        }else if(event.body.code == 401 && request.url != environment.baseUrl + environment.login   ){
          this.toast.warning("Tu es pas autoriser a acceder aux ressource  " /*request.url*/,"Erreur");
        }
        else {
         // alert('ok')
        }
        if(event.status !== 200){
          this.toast.warning("Une erreur interne s'est produite","Erreur");
        }
      }
     // console.log("I AM THE INTERCEPTOR AND I INTERCEP THIS URL " + request.url)
      return event

    }))



    function handleRoute() {
      switch (true) {
        case url.endsWith('/users/authenticate') && method === 'POST':
          return authenticate();
        case url.endsWith('/users') && method === 'GET':
          return getUsers();
        default:
          // pass through any requests not handled above
         // console.log("interceptor")
          return next.handle(request);
      }
    }

    // route functions

    function authenticate() {
      const { username, password } = body;
      const user = users.find(x => x.username === username && x.password === password);
      if (!user) return error('Username or password is incorrect');
      return ok({
        id: user.id,
        username: user.username,
        firstName: user.firstName,
        lastName: user.lastName
      })
    }

    function getUsers() {
      if (!isLoggedIn()) return unauthorized();
      return ok(users);
    }

    // helper functions

    function ok(body?) {
      return of(new HttpResponse({ status: 200, body }))
    }

    function error(message) {
      return throwError({ error: { message } });
    }

    function unauthorized() {
      return throwError({ status: 401, error: { message: 'Unauthorised' } });
    }

    function isLoggedIn() {
      return headers.get('Authorization') === `Basic ${window.btoa('test:test')}`;
    }
  }
}

class User {
  id: number;
  username: string;
  firstName: string;
  lastName:string;
  password:string;
}
