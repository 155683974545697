import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-carte-employe-module',
  templateUrl: './carte-employe-module.component.html',
  styleUrls: ['./carte-employe-module.component.css']
})
export class CarteEmployeModuleComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}
