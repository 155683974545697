import { Component, OnInit, TemplateRef } from '@angular/core';
import { ActivatedRoute } from "@angular/router";
import { AuthServiceService } from "../../../service/auth-service.service";
import * as $ from 'jquery';
import Swal from 'sweetalert2';
import { Translatable } from "../../../../Translatable";
import { HttpClient } from "@angular/common/http";
import { ToastrService } from "ngx-toastr";
import { HelperService } from "../../../service/helper.service";
import { NgxUiLoaderService } from "ngx-ui-loader";
import { DataTablesResponse } from "../../../model/DataTablesResponse";
import { valuesys } from "../../../../../options";
import { modules } from "../../../model/db";
import { environment } from "../../../../../environments/environment";
import {BsModalRef, BsModalService} from "ngx-bootstrap";
@Component({
  selector: 'app-module',
  templateUrl: './module.component.html',
  styleUrls: ['./module.component.css']
})
export class ModuleComponent extends Translatable implements OnInit {

  title: string;
  dtOptions: DataTables.Settings = {};
  modalRef: BsModalRef;
  titleModal: string;
  isAdd: boolean = true;
  isValid: boolean = false;
  public modules: modules[] = [];
  public module_: modules = new modules();
  module_error: modules;
  constructor(private activatedRoute: ActivatedRoute,
    private auth: AuthServiceService,
    private http: HttpClient,
    private toastr: ToastrService,
    private helper: HelperService,
    private modalService: BsModalService
  ) {
    super();
  }


  async ngOnInit() {
    this.initDatatable()
  }
  initDatatable() {
    this.isAdd = true;
    this.isValid = false;
    this.title = this.__("label_sm_module");
    this.dtOptions = this.helper.dataTableOption();
    this.dtOptions.columns = [
      {
        "data": "libelle"
      },
      {
        "data": "code"
      }
    ]
    this.dtOptions.ajax = (dataTablesParameters: any, callback) => {
      this.http
        .get<DataTablesResponse>(
          environment.baseUrl + environment.modules, valuesys.httpAuthOptionsProcess($.param(dataTablesParameters))
        ).subscribe(resp => {
          this.modules = resp.data;
          callback({
            recordsTotal: resp.recordsTotal,
            recordsFiltered: resp.recordsFiltered,
            data: []
          });
        });
    }
  }



  async addForm() {
    this.isValid = true;
    if (this.isAdd) {
      console.log(this.module_);
      let res = await this.http.post<any>(environment.baseUrl + environment.modules, this.module_, valuesys.httpAuthOptions()).toPromise();
      if (res.code === 201) {
        this.toastr.success(res.msg, this.__("msg.success"));
        //this.rerender();
        this.modules.push(res.data)
        this.close()
      }else if(res.code === 400){
        //this.toastr.warning(res.data.join('<br>'),"Avvertissement") ;
        let msg = ''
        for (let item in res.data){
          msg += res.data[item][0] + "\n";
          this.module_error[item] = res.data[item][0] ;
        }
        this.toastr.warning(msg,this.__("msg.warning")) ;
      }
      else {
        this.toastr.error(res.msg, this.__("msg.error"));
      }
    } else {
      console.log(this.module_);
      let res = await this.http.put<any>(environment.baseUrl + environment.modules, this.module_, valuesys.httpAuthOptions()).toPromise();
      if (res.code === 201) {
        this.toastr.success(res.msg, this.__("msg.success"));
        this.close()
      } else if(res.code === 400){
        //this.toastr.warning(res.data.join('<br>'),"Avvertissement") ;
        let msg = ''
        for (let item in res.data){
          msg += res.data[item][0] + "\n";
          this.module_error[item] = res.data[item][0] ;
        }
        this.toastr.warning(msg,this.__("msg.warning")) ;
      }
      else {
        this.toastr.error(res.msg, this.__("msg.error"));
      }
    }
    this.isValid = false;

  }
  async openModal(template: TemplateRef<any>, current: modules = null) {
    this.module_error = new modules();
    console.log("MODAL CONTEN", template)
    if (current == null) {
      this.isAdd = true;
      this.module_ = new modules();
      this.titleModal = this.__('modules.new');
    } else {
      this.isAdd = false;
      this.titleModal = this.__('modules.edit');
      let res = await this.http.get<any>(environment.baseUrl + environment.modules + '?id=' + current.id + '&child=bureau,profil', valuesys.httpAuthOptions()).toPromise();
      this.module_ = current;
    }
    this.modalRef = this.modalService.show(template,{ignoreBackdropClick:true});
  }
  close() {
    this.module_ = new modules();
    this.titleModal = '';
    this.modalRef.hide();
    this.module_error = new modules();
  }
  delete(deleted: modules) {
    Swal.fire({
      title: this.__('msg.confirm'),
      text: this.__('msg.warning_delete'),
      icon: 'warning',
      showCancelButton: true,
      confirmButtonText: this.__('msg.confirmed_delete'),
      cancelButtonText: this.__('msg.cancel_delete')
    }).then(async (result) => {
      if (result.value) {
        let sup = await this.http.delete<any>(environment.baseUrl + environment.modules + "?id=" + deleted.id, valuesys.httpAuthOptions()).toPromise();
        // this.rerender();
        if (sup.code == 204) {
          this.modules = this.modules.filter(function (item, index) {
            return item.id != deleted.id
          })
          Swal.fire(
            this.__('msg.deleted'),
            sup.msg,
            'success'
          )
        } else {
          this.toastr.error(sup.msg, this.__("msg.error"));
        }

        // For more information about handling dismissals please visit
        // https://sweetalert2.github.io/#handling-dismissals
      } else if (result.dismiss === Swal.DismissReason.cancel) {
        Swal.fire(
          this.__('msg.canceled'),
          this.__('msg.canceled_delete'),
          'error'
        )
      }
    })
  }



  async toggleState(current: modules, state: number, btn: any) {
    console.log(btn.hidden = true)
    $(btn).attr('disabled');
    btn.disabled = true;
    let toogle = await this.http.get<any>(environment.baseUrl + environment.moduleEdit + `?id=${current.id}&etat=${state}`, valuesys.httpAuthOptions()).toPromise();
    if (toogle.code == 201) {
      this.toastr.success(toogle.msg, this.__("msg.success"));
      current.etat = toogle.data.etat

    } else {
      this.toastr.error(toogle.msg, this.__("msg.error"));
    }
    btn.disabled = false;
  }

}
