import { Component, OnInit, TemplateRef } from '@angular/core';
import {wallet, tarif_frais, pay_transaction, pay_employe, devise} from '../../../../model/db';
import { HttpClient } from '@angular/common/http';
import { environment } from 'src/environments/environment';
import { valuesys } from 'src/options';
import { Translatable } from "../../../../../Translatable";
import {HelperService} from "../../../../service/helper.service";
import { ToastrService } from 'ngx-toastr';
declare const require: any;
const jsPDF = require('jspdf');
require('jspdf-autotable');
import html2canvas from 'html2canvas';
import {BsModalRef, BsModalService} from "ngx-bootstrap";

@Component({
  selector: 'app-crediter-wallet',
  templateUrl: './crediter-wallet.component.html',
  styleUrls: ['./crediter-wallet.component.css']
})
export class CrediterWalletComponent extends Translatable implements OnInit {

  showResult: boolean = false
  public telephone: any;
  public beneficiaire: pay_employe;
  private objetPhone: any;
  modalRef: BsModalRef;
  public titleModal: any;
  partie1: boolean = false;
  partie2: boolean = true;
  montant: any;
  code: any;
  motifs: string;
  idCodeValidation: any;
  frais_calculate: any ={
    "ht": 0,
    "tva":0,
    "ttc": 0,
    "res": 3
  }
  frais: tarif_frais;
  isValidated: boolean = false;
  public recharge: any = {};
  public numTransac: any;
  element : any ;
  currenCode :string =environment.CURREN_CODE_COUNTRY;
  public devises: devise[] = [];
  code_devise: string;

  constructor(
    private http: HttpClient ,private  helper : HelperService,private toastr: ToastrService,private modalService: BsModalService
  ) {
    super();
  }

  ngOnInit() {
  }

  async search() {
    let res = await this.http.get<any>(  environment.baseUrl+ environment.detailWalletEmploye+"?telephone="+this.telephone,valuesys.httpAuthOptions()).toPromise();
    if (res.data.length > 0) {
      this.beneficiaire = res.data[0]
      //console.log(res.data[0])
      this.showResult = true
    }else {
      this.showResult = false
      this.toastr.error(this.__("msg.no_wallet"), this.__("msg.error"));
    }
  }

  async openModalRechargeEspeceWallet(template: TemplateRef<any>, beneficiaires: pay_employe = null) {
    this.devises = await this.helper.getSelectList(environment.baseUrl+ environment.devise,['nom', 'code'], 'code');
    this.titleModal = this.__('wallet.recharge_espece');
    this.modalRef = this.modalService.show(template,{ignoreBackdropClick:true});
    this.partie1 = false;
    this.frais = new tarif_frais();
    this.frais.ht = 0;
  }

  async validatePartie1() {
    let res = await this.http.get<any>(environment.baseUrl + environment.payWalletGenererCode + "?tel=" +this.telephone+"&montant="+this.montant+"&code_devise="+this.code_devise+"&service_id="+environment.CODE_PAIEMENT_SALAIRE+"&motifs="+this.motifs, valuesys.httpAuthOptions()).toPromise();
    if(res.code == 201){
        this.partie1 = true;
        this.partie2 = false;
        this.idCodeValidation =res.data.Resultat;
      this.toastr.success(this.__('beneficiaire.code_sended'), this.__("msg.success"));
    }else {
      this.toastr.error(res.msg, this.__("msg.error"));
    }

  }

  async validatePartie2() {
    let res = await this.http.put<any>(environment.baseUrl + environment.payWalletActiverCode, {id:this.idCodeValidation ,code: this.code, tel: this.telephone, montant: this.montant, service_id: environment.CODE_PAIEMENT_SALAIRE, motifs:this.motifs, code_devise: this.code_devise }, valuesys.httpAuthOptions()).toPromise();

    console.log(this.numTransac)
    if (res.code == 201 ) {
      this.helper.getSoldeBureau();
      this.numTransac = res.data.numtransac
      this.toastr.success(this.__('msg.transac_succes'), this.__("msg.success"));
      let res1 = await this.http.get<any>(environment.baseUrl + environment.payHistoriqueTransaction+`?child=wallet,paySociete,utilisateur&where=num_transac|e|${this.numTransac}`, valuesys.httpAuthOptions()).toPromise();
      this.recharge = res1.data[0]
      console.log(this.recharge)
      this.close();
      this.isValidated = true
    } else {
      this.toastr.error(res.msg, this.__("msg.error"));
    }
  }

  close() {
    this.modalRef.hide();
    this.partie2 = true;
    this.partie1 = true;
    this.showResult = false;
    this.beneficiaire = new pay_employe();
    this.telephone = null ;
    this.montant = null;
  }

  retour() {
    this.isValidated = false;
    this.beneficiaire = new pay_employe()
    this.telephone = null ;
    this.montant = null
    this.code = null
    this.idCodeValidation = null
  }

  onCountryChange(m){
    let lastDiall =  this.objetPhone .dialCode ;
    let newDiall = m.dialCode
    console.log(`LAST = ${lastDiall} ; NEW ${newDiall}`,m);
    this.element.value = this.element.value.replace(lastDiall+'',newDiall+'');
    this.telephone  = this.element.value
    this.objetPhone = m ;
  }

  telInputObject(m){
    console.log('OBJET IN',m.s)
    this.objetPhone = m.s ;
  }

  controle(element){
    this.element = element ;
    element.value = element.value.replace(/[^0-9]/gi,'');

    if((element.value || "").substr(0,this.objetPhone.dialCode.length).indexOf(this.objetPhone.dialCode.toString()) == -1){
      if(element.value.length <=  this.objetPhone.dialCode.length  && (element.value || "").substr( this.objetPhone.dialCode.length ).length < 1)
      {
        element.value =  this.objetPhone.dialCode;
      }
      else
      {
        if((element.value || "").substr(0,this.objetPhone.dialCode.length ).indexOf( this.objetPhone.dialCode.toString()) !== -1){
          element.value =  element.value;
        }
        else {
          element.value = this.objetPhone.dialCode +  element.value;
        }
      }
    }
    this.telephone =  element.value
  }

  downloadRecu() {
    let data = document.getElementById('recu');
    html2canvas(data).then(canvas => {
      // Few necessary setting options
      let imgWidth = 200;
      let pageHeight = 400;
      let imgHeight = canvas.height * imgWidth / canvas.width;
      let heightLeft = imgHeight;

      const contentDataURL = canvas.toDataURL('image/png')
      let pdf = new jsPDF('p', 'mm', 'a4'); // A4 size page of PDF
      let x = 2;
      let y = 2;
      pdf.addImage(contentDataURL, 'PNG', x, y, imgWidth, imgHeight)
      pdf.save(`TransactionNum${this.numTransac}.pdf`); // Generated PDF
    })
  }

    getDate(date){
        if(date != '' && date != null) {
            const tab = date.split(' ');

            const d = tab[0].split('-');
            if (tab.length == 2) {
                const dt = d[2] + "/" + d[1] + "/" + d[0] + " " + this.__("a") + " " + tab[1];
                return dt;
            }
            else {
                const dt = d[2] + "/" + d[1] + "/" + d[0];
                return dt;
            }
        }
        else return 'Pas de date';
    }
}
