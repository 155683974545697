import {AfterViewInit, Component, OnDestroy, OnInit, ViewChild} from '@angular/core';
import {Translatable} from "../../../../Translatable";
import {environment} from "../../../../../environments/environment";
import {HelperService} from "../../../service/helper.service";
import {action_utilisateur, modules, response, utilisateur} from "../../../model/db";
import {valuesys} from "../../../../../options";
import {HttpClient} from "@angular/common/http";
import {DataTablesResponse} from "../../../model/DataTablesResponse";
import * as $ from "jquery";
import {DataTableDirective} from "angular-datatables";
import {Subject} from "rxjs";

@Component({
  selector: 'app-suivi-action',
  templateUrl: './suivi-action.component.html',
  styleUrls: ['./suivi-action.component.css']
})

export class SuiviActionComponent extends Translatable implements OnInit, AfterViewInit, OnDestroy {
  @ViewChild(DataTableDirective, {static: false})
  dtElement: DataTableDirective;

  // @ts-ignore
  dtTrigger: Subject = new Subject();

  ngAfterViewInit(): void {
    this.dtTrigger.next();
  }

  ngOnDestroy(): void {
    this.dtTrigger.unsubscribe();
  }

  rerender(): void {
    this.dtElement.dtInstance.then((dtInstance: DataTables.Api) => {
      // Destroy the table first
      dtInstance.destroy();
      // Call the dtTrigger to rerender again
      this.dtTrigger.next();
    });
  }

  colorTheme = 'theme-dark-blue';

  modules: modules [] = [];
  private now = (new Date()).toISOString().replace('T',' ').substr(0,10);
  private endTime = " 23:59:59";
  date_debut : string = this.now;
  date_fin : string= this.now;
  selectedModule : string;
  title:string ;
  init_=true;
  dtOptions: DataTables.Settings = {};
  public actions  = [] ;

  constructor(
    private helper: HelperService,
    private http: HttpClient,
  ) {
    super();
  }

  async ngOnInit() {

    this.iniPeriode();
    this.initDatatable()
  }


  initDatatable(){
    this.title = this.__("actions_.suivi");
    this.dtOptions = this.helper.dataTableOption();
    this.dtOptions.columns = [
      {
        "data": "date"
      },
      {
        "data": "action"
      },
      {
        "data": "utilisateur"
      }
    ]
    this.dtOptions.ajax = (dataTablesParameters: any, callback) => {
      /*this.http
          .get<DataTablesResponse>(
              environment.baseUrl+ environment.log, valuesys.httpAuthOptionsProcess($.param(dataTablesParameters))
          ).subscribe(resp => {
        this.actions = resp.data;
        callback({
          recordsTotal: resp.recordsTotal,
          recordsFiltered: resp.recordsFiltered,
          data:[]
        });
      });*/
      if(this.init_) {
        this.http
          .get<DataTablesResponse>(
            environment.baseUrl + environment.log+`?child=utilisateur&where=module_id|e|14,date|se|${this.date_debut},date|ie|${this.date_fin+this.endTime}`, valuesys.httpAuthOptionsProcess($.param(dataTablesParameters))
          ).subscribe(resp => {
          this.actions = resp.data;
          console.log(this.actions)
          callback({
            recordsTotal: resp.recordsTotal,
            recordsFiltered: resp.recordsFiltered,
            data:[]
          });
        });
      }else{
        this.http
          .get<DataTablesResponse>(
            environment.baseUrl + environment.log+`?child=utilisateur&where=module_id|e|14,date|se|${this.date_debut},date|ie|${this.date_fin+this.endTime}`, valuesys.httpAuthOptionsProcess($.param(dataTablesParameters))
          ).subscribe(resp => {
          this.actions = resp.data;
          console.log(this.actions)
          callback({
            recordsTotal: resp.recordsTotal,
            recordsFiltered: resp.recordsFiltered,
            data:[]
          });
        });
      }
    }
  }
  formatDate(date) {
    console.log(date)
    var d = date.split('/');
    return [d[2], d[0], d[1]].join('-');
  }

  async search() {
    this.init_ = false;
    let periode = $('#periode').val();
    console.log(periode)
    if(periode != ''){
      let tab = periode.toString().split(' - ');
      this.date_debut = this.formatDate(tab[0]);
      this.date_fin = this.formatDate(tab[1]);
    }
    this.rerender()
  }

    iniPeriode(){
        let dayNow = new Date()
        let now = (dayNow).toISOString().substr(0,10)
        let now_ = (dayNow).toISOString().substr(0,10).split('-').reverse().join("/")
        this.date_debut = now;
        this.date_fin = now;
        $('#periode').val(now_ + " - " + now_);
    }

    getDate(date){
        if(date != '' && date != null) {
            const tab = date.split(' ');

            const d = tab[0].split('-');
            if (tab.length == 2) {
                const dt = d[2] + "/" + d[1] + "/" + d[0] + " " + this.__("a") + " " + tab[1];
                return dt;
            }
            else {
                const dt = d[2] + "/" + d[1] + "/" + d[0];
                return dt;
            }
        }
        else return 'Pas de date';
    }
}
