import {Component, OnInit} from '@angular/core';
import * as moment from "moment";
import 'moment/locale/fr-ca';
import {HelperService} from "./gestion/service/helper.service";
@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css']
})
export class AppComponent implements OnInit {
  title = 'phcoAappweb';
  constructor(private helper:HelperService) {
    this.helper.guard()
  }

  async ngOnInit() {
    // this.helper.guard()
  }
}
