import {Component, OnInit, TemplateRef} from '@angular/core';
import {affectation_droit, modules, profil, profilage_module, type_profil} from "../../../model/db";
import {ActivatedRoute} from "@angular/router";
import {AuthServiceService} from "../../../service/auth-service.service";
import {HttpClient} from "@angular/common/http";
import {ToastrService} from "ngx-toastr";
import {HelperService} from "../../../service/helper.service";
import {environment} from "../../../../../environments/environment";
import {valuesys} from "../../../../../options";
import Swal from 'sweetalert2' ;
import {Translatable} from "../../../../Translatable";
import {DataTablesResponse} from "../../../model/DataTablesResponse";
import {NgxUiLoaderService} from "ngx-ui-loader";
import {BsModalRef, BsModalService} from "ngx-bootstrap";

@Component({
  selector: 'app-profil',
  templateUrl: './profil.component.html',
  styleUrls: ['./profil.component.css']
})
export class ProfilComponent extends Translatable implements OnInit {






  //DATATABLE

  title:string ;
  dtOptions: DataTables.Settings = {};

  titleModal:string ;
  titleProfilage:string=this.__('profil.profils') ;
  isAdd : boolean = true ;
  isValid : boolean = false ;
  modalRef: BsModalRef;
  droits:any = []
  modules:profilage_module [] = []


  public typeProfils:type_profil[] =[];
  public allDroit:affectation_droit[] =[];

  public profils:profil [] = [];
  public listeModules:modules[]  = [new modules()];
  public profil:profil  = new profil();
  profil_error: profil;
  constructor(private activatedRoute: ActivatedRoute,
              private auth : AuthServiceService,
              private http: HttpClient,
              private toastr: ToastrService,
              private helper: HelperService,
              private modalService: BsModalService,
              private ngxService: NgxUiLoaderService
  ) {
    super();
  }
  async ngOnInit() {


    this.initDatatable()
    /* for (let droit of this.allDroit){
      console.log("les droits "+droit)
      }*/

    console.log(" liste des droits "+this.listeModules);


    //for (let droit:any in this.droits){
    //   console.log(droit)
    // let sm =[]
    //  for (let s_m in droit.sous_module){
    //      sm.push(s_m)
    //}
    // let module:any = {module:droit.module.libelle};
    // module.sousmodule = sm ;

    // droitss.push(module)
    // }
    //console.log("GET DROIT  ",this.droits[1]  );


  }

  initDatatable(){

    this.isAdd = true ;
    this.isValid = false ;
    this.title = this.__("lebel_users");
    this.dtOptions = this.helper.dataTableOption();
    this.dtOptions.columns = [
      {data:"libelle"},
      {data:"typeProfil"},
      {data:"etat"}
    ]  ;
    this.dtOptions.ajax = (dataTablesParameters: any, callback) => {
      this.http
        .get<DataTablesResponse>(
          environment.baseUrl+ environment.profil+"?child=typeProfil", valuesys.httpAuthOptionsProcess($.param(dataTablesParameters))
        ).subscribe(resp => {
        this.profils = resp.data;
        callback({
          recordsTotal: resp.recordsTotal,
          recordsFiltered: resp.recordsFiltered,
          data:[]
        });
      });
    }
  }

  async addProfilForm(){
    this.isValid = true ;
    if(this.isAdd ){
      console.log(this.profil);
      let res =  await this.http.post<any>(environment.baseUrl+ environment.profil,this.profil,valuesys.httpAuthOptions()).toPromise();
      if(res.code === 201){
        this.toastr.success(res.msg,this.__("msg.success")) ;
        res.data.typeProfil = this.typeProfils.filter((item:type_profil)=> item.id == this.profil.type_profil_id)[0];
        //this.rerender();
        this.profils.push(res.data);
        this.close()
      }
      else if(res.code === 400){
        //this.toastr.warning(res.data.join('<br>'),"Avvertissement") ;
        let msg = ''
        for (let item in res.data){
          msg += res.data[item][0] + "\n";
          this.profil_error[item] = res.data[item][0] ;
        }
        this.toastr.warning(msg,this.__("msg.warning")) ;
      }
      else {
        this.toastr.error(res.msg,this.__("msg.error")) ;
      }
    }else {
      console.log(this.profil);
      let res =  await this.http.put<any>(environment.baseUrl+ environment.profil,this.profil,valuesys.httpAuthOptions()).toPromise();

      if(res.code === 201){
        this.toastr.success(res.msg,this.__("msg.success")) ;

        this.close()
      }
      else if(res.code === 400){
        //this.toastr.warning(res.data.join('<br>'),"Avvertissement") ;
        let msg = ''
        for (let item in res.data){
          msg += res.data[item][0] + "\n";
          this.profil_error[item] = res.data[item][0] ;
        }
        this.toastr.warning(msg,this.__("msg.warning")) ;
      }
      else {
        this.toastr.error(res.msg,this.__("msg.error")) ;
      }
    }
    this.isValid = false ;

  }
  async openModal(template: TemplateRef<any>,currenProfil:profil =null) {
    this.profil_error = new profil();
   // let res = await this.http.get<any>(environment.baseUrl+ environment.typeProfil,valuesys.httpAuthOptions()).toPromise();
    this.typeProfils =  await this.helper.getSelectList(environment.baseUrl+ environment.typeProfil,['libelle'])

    // this.typeProfils = [] ;
    // let tp = new  type_profil();
    //
    // tp.etat=1;
    // tp.id=1;
    // tp.libelle='Administrateur';



    // this.typeProfils.push(r);

    if(currenProfil == null){
      this.profil = new profil();
      this.isAdd = true ;

      this.titleModal = this.__('profil.add') ;
    }else {
      this.isAdd = false ;
      this.titleModal = this.__('profil.edit') ;
      let res = await this.http.get<any>(environment.baseUrl+ environment.profil + '?id=' + currenProfil.id,valuesys.httpAuthOptions()).toPromise();

      //this.user = res.data ;
      this.profil = currenProfil ;

    }

    this.modalRef = this.modalService.show(template,{ignoreBackdropClick:true});
  }
  close(){
    this.profil_error = new profil();
    this.profil = new profil();
    this.titleModal = "" ;
    this.modalRef.hide()
  }
  delete(deleteProfil:profil){
    Swal.fire({
      title: this.__('msg.confirm'),
      text: this.__('msg.warning_delete'),
      icon: 'warning',
      showCancelButton: true,
      confirmButtonText: this.__('msg.confirmed_delete'),
      cancelButtonText: this.__('msg.cancel_delete')
    }).then(async (result) => {
      if (result.value) {
        let sup = await this.http.delete<any>(environment.baseUrl+environment.profil+"?id="+deleteProfil.id,valuesys.httpAuthOptions()).toPromise();
        // this.rerender();
        if(sup.code == 204){
          this.profils= this.profils.filter(function (item,index) {
            return item.id != deleteProfil.id
          });
          Swal.fire(
            this.__('msg.deleted'),
            sup.msg,
            'success'
          )
        }else {
          this.toastr.error(sup.msg,this.__("msg.error"));
        }

        // For more information about handling dismissals please visit
        // https://sweetalert2.github.io/#handling-dismissals
      } else if (result.dismiss === Swal.DismissReason.cancel) {
        Swal.fire(
          this.__('msg.canceled'),
          this.__('msg.canceled_delete'),
          'error'
        )
      }
    })
  }


  async toggleState(profile:profil,state:number,btn:any){
    console.log(btn.hidden = true)
    $(btn).attr('disabled');
    btn.disabled = true;
    let toogle = await this.http.get<any>(environment.baseUrl+ environment.profilEdit+`?id=${profile.id}&etat=${state}`,valuesys.httpAuthOptions()).toPromise();
    if(toogle.code == 201){
      this.toastr.success(toogle.msg,this.__("msg.success"));
      profile.etat = toogle.data.etat

    }else {
      this.toastr.error(toogle.msg,this.__("msg.error"));
    }
    btn.disabled = false;
  }

  async detail(detailProfil:profil, template:TemplateRef<any>){
    // alert(detailProfil.libelle)
    this.titleProfilage = this.__('profil.profilage') + " : "+ detailProfil.libelle ;
    this.profil= detailProfil

    let res =  await this.http.get<any>(environment.baseUrl+ environment.affectation_droit+"?id="+detailProfil.id,valuesys.httpAuthOptions()).toPromise();
    this.modules = res.data;
    console.log("yyyyy",      this.modules)

    this.modalRef = this.modalService.show(template,{class:'modal-lg',ignoreBackdropClick:true});

  }


  async addProfilage(){


  }

  async updateAction(droit,elm){
    elm.disabled= true;

    if(elm.checked ==true){
      let res =  await this.http.post<any>(environment.baseUrl+ environment.affectation_droit,{droit_id:droit.id,profil_id:this.profil.id,etat:1},valuesys.httpAuthOptions()).toPromise();
      if(res.code === 201){
        this.toastr.success(res.msg,this.__("msg.success")) ;
        droit.etat = 1 ;
        elm.checked= true;
      }else {
        elm.checked= false;
        this.toastr.error(res.msg,this.__("msg.error")) ;
      }
    }else{
      let res =  await this.http.post<any>(environment.baseUrl+ environment.affectation_droit,{droit_id:droit.id,profil_id:this.profil.id,etat:0},valuesys.httpAuthOptions()).toPromise();
      if(res.code === 201){
        this.toastr.success(res.msg,this.__("msg.success")) ;
        droit.etat = 0 ;
        elm.checked= false;
      }else {
        elm.checked= true;
        this.toastr.error(res.msg,this.__("msg.error")) ;
      }
    }

    elm.disabled= false;
  }
  async updateActionAll(droit,elm){
    let id_droits = droit.map((item)=> item.id).join(',');
    elm.disabled= true;

    if(elm.checked ==true){
      let res =  await this.http.post<any>(environment.baseUrl+ environment.affectation_droit,{droit_id:id_droits,profil_id:this.profil.id,etat:1},valuesys.httpAuthOptions()).toPromise();
      if(res.code === 201){
        this.toastr.success(res.msg,this.__("msg.success")) ;
        droit = droit.map((item)=> {
          item.etat = 1 ;
          return item ;
        });
        elm.checked= true;
      }else {
        droit = droit.map((item)=> {
          if(item.etat == 0)
            item.etat = 0 ;
          return item ;
        });
        elm.checked= false;
        this.toastr.error(res.msg,this.__("msg.error")) ;
      }
    }else{
      let res =  await this.http.post<any>(environment.baseUrl+ environment.affectation_droit,{droit_id:id_droits,profil_id:this.profil.id,etat:0},valuesys.httpAuthOptions()).toPromise();
      if(res.code === 201){
        this.toastr.success(res.msg,this.__("msg.success")) ;
        droit = droit.map((item)=> {
          item.etat = 0 ;
          return item ;
        });
        elm.checked= false;
      }else {
        droit = droit.map((item)=> {
          if(item.etat == 1)
            item.etat = 0 ;
          item.etat = 1 ;
          return item ;
        });
        elm.checked= true;
        this.toastr.error(res.msg,this.__("msg.error")) ;
      }
    }

    elm.disabled= false;
  }
  isCheckedAll(s_module:any){
    return  s_module.droits.filter((item)=>item.etat == 1).length ==  s_module.droits.length
  }
  toogleAction(elm,block){

    console.log( elm.className);
    console.log( block.className);
    if(elm.className == "fa fa-eye"){
      elm.className = "fa fa-eye-slash";
      block.className = "show";
    }else if(elm.className == "fa fa-eye-slash") {
      elm.className = "fa fa-eye";
      block.className = "hidden";
    }
  }

}
