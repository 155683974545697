import {HttpHeaders} from "@angular/common/http";
import {environment} from './environments/environment';
// @ts-ignore

export const valuesys = {
    httpOptions : {
        headers: new HttpHeaders({
            'Content-Type':  'application/json',
            'lang':  localStorage.getItem('lang') || "fr"
        })
    },
    httpAuthOptions : () => {
        return {
            headers: new HttpHeaders({
                'Content-Type':  'application/json',
                'token' :  localStorage.getItem(environment.authItemName) || '',
                'lang':  localStorage.getItem('lang') || "fr"
            }),
        }
    },
    httpUploadFileOptions : () => {
        return {
            headers: new HttpHeaders({
                'Content-Type':  'multipart/form-data',
                'Accept':  'application/json',
                'token' :  localStorage.getItem(environment.authItemName) || '',
                'lang':  localStorage.getItem('lang') || "fr"
            }),
        }
    },
  httpAuthOptionsProcess : (prodess = '') => {
        return {
            headers: new HttpHeaders({
                'Content-Type':  'application/json',
                'token' :  localStorage.getItem(environment.authItemName) || '',
                'lang':  localStorage.getItem('lang') || "fr",
                'process': prodess
            }),
        }
    },
    authRefreshInterval: 25 * 60 * 1000,//25 mm
};
