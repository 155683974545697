import {AfterViewInit, Component, OnDestroy, OnInit, ViewChild} from '@angular/core';
import {ActivatedRoute} from "@angular/router";
import {AuthServiceService} from "../../../../service/auth-service.service";
import {HttpClient} from "@angular/common/http";
import {ToastrService} from "ngx-toastr";
import {HelperService} from "../../../../service/helper.service";
import {NgxUiLoaderService} from "ngx-ui-loader";
import {DataTablesResponse} from "../../../../model/DataTablesResponse";
import { Translatable } from "../../../../../Translatable";
import { pay_transaction,carte } from '../../../../model/db';
import { environment } from 'src/environments/environment';
import { valuesys } from 'src/options';
import { DataTableDirective } from 'angular-datatables';
import * as $ from 'jquery'
import { Subject } from 'rxjs';
import html2canvas from "html2canvas";
import {BsModalRef, BsModalService} from "ngx-bootstrap";
declare const require: any;
const jsPDF = require('jspdf');
require('jspdf-autotable');


@Component({
  selector: 'app-historique-transaction-carte',
  templateUrl: './historique-transaction-carte.component.html',
  styleUrls: ['./historique-transaction-carte.component.css']
})
export class HistoriqueTransactionCarteComponent extends Translatable implements OnInit, AfterViewInit, OnDestroy {

  private now = (new Date()).toISOString().replace('T', ' ').substr(0, 10);
  private endTime = " 23:59:59";
  date_debut: string = this.now;
  date_fin: string = this.now;
  showResult: boolean = false
  objetPhone: any;
  public transactions: pay_transaction[] = [];
  public carte: carte = new carte();
  public transaction: pay_transaction
  dtOptions: DataTables.Settings = {};
  title: any;
  isValid: boolean;
  telephone: any ;
  init_=true;
  public transac: any = {};
  public numTransac: any;


  ngAfterViewInit(): void {
    this.dtTrigger.next();
  }

  ngOnDestroy(): void {
    this.dtTrigger.unsubscribe();
  }
  rerender(): void {
    this.dtElement.dtInstance.then((dtInstance: DataTables.Api) => {
      // Destroy the table first
      dtInstance.destroy();
      // Call the dtTrigger to rerender again
      this.dtTrigger.next();
    });
  }
  /*ddd*/
  @ViewChild(DataTableDirective, {static: false})
  dtElement: DataTableDirective;

  // @ts-ignore
  dtTrigger: Subject = new Subject();
  /*ddd*/

  constructor( private http: HttpClient,private helper: HelperService, private toastr: ToastrService ) {
    super();
  }

  ngOnInit() {
    this.initDatatable()
  }
  initDatatable() {
    //console.log(this.telephone);
    this.isValid = false;
    this.title = this.__("wallet.historique");
    this.dtOptions = this.helper.dataTableOption();
    this.dtOptions.columns = [
      {data:"datetransac"},
      {data:"montant"},
      {data:"commission"},
      {data:'wallet_id'},
      {data:"service"},
      {data:"",searchable:false},
      {data:"",searchable:false}
    ];

    this.dtOptions.ajax =async (dataTablesParameters: any, callback) => {
      if(this.init_){

        this.http
          .get<DataTablesResponse>(
            environment.baseUrl + environment.historiqueTransactionCarte +`?child=paySociete&where=carte_id|d|null`, valuesys.httpAuthOptionsProcess($.param(dataTablesParameters))
          ).subscribe(resp => {
          this.transactions = resp.data;
          console.log(this.transactions);

          callback({
            recordsTotal: resp.recordsTotal,
            recordsFiltered: resp.recordsFiltered,
            data: []
          });
        });
      }
      else if(this.telephone && this.objetPhone.dialCode != this.telephone ){

        let res = await this.http.get<any>(  environment.baseUrl+environment.carte+`?where=telephone|e|${this.telephone}`,valuesys.httpAuthOptions()).toPromise();
        let carte  = res.data[0] ||  {};
        console.log(carte.id);
        this.http
          .get<DataTablesResponse>(
            environment.baseUrl + environment.historiqueTransactionCarte +`?child=pay_societe&where=carte_id|e|${carte.id || 0},date_transaction|se|${this.date_debut},date_transaction|ie|${this.date_fin + this.endTime}`, valuesys.httpAuthOptionsProcess($.param(dataTablesParameters))
          ).subscribe(resp => {
          this.transactions = resp.data;
          console.log(this.transactions);

          callback({
            recordsTotal: resp.recordsTotal,
            recordsFiltered: resp.recordsFiltered,
            data: []
          });
        });
      }else if(this.objetPhone.dialCode == this.telephone ||  !this.telephone ) {

        this.http
          .get<DataTablesResponse>(
            environment.baseUrl + environment.historiqueTransactionCarte+`?child=pay_societe&where=carte_id|d|null,date_transaction|se|${this.date_debut},date_transaction|ie|${this.date_fin + this.endTime}`, valuesys.httpAuthOptionsProcess($.param(dataTablesParameters))
          ).subscribe(resp => {
          this.transactions = resp.data;
          console.log(this.transactions);
          /*if (resp.data.length > 0 ) {
            this.transaction = resp.data[0]
            console.log(resp.data[0])
            this.showResult = true
          }else {
            this.showResult = false
            this.telephone = null
            this.toastr.error('Compte non trouvée', "Erreur");
          }*/
          callback({
            recordsTotal: resp.recordsTotal,
            recordsFiltered: resp.recordsFiltered,
            data: []
          });
        });
      }

    }
  }

  async openRecu(currentTransaction:pay_transaction =null,openRecuBtn) {
    openRecuBtn.disabled = true;
    let res1 = await this.http.get<any>(environment.baseUrl+environment.historiqueTransactionCarte+`?child=utilisateur,pay_societe,carte&where=id|e|`+ currentTransaction.id, valuesys.httpAuthOptions()).toPromise();
    this.transac = res1.data[0];
    this.numTransac = res1.data[0].numtransac;
    console.log(this.transac)
    openRecuBtn.disabled = false;
    this.showResult = true;
  }

  retour() {
    this.showResult = false;
  }

  downloadRecu() {
    let data = document.getElementById('recu');
    html2canvas(data).then(canvas => {
      // Few necessary setting options
      let imgWidth = 200;
      let pageHeight = 400;
      let imgHeight = canvas.height * imgWidth / canvas.width;
      let heightLeft = imgHeight;

      const contentDataURL = canvas.toDataURL('image/png')
      let pdf = new jsPDF('p', 'mm', 'a4'); // A4 size page of PDF
      let x = 2;
      let y = 2;
      pdf.addImage(contentDataURL, 'PNG', x, y, imgWidth, imgHeight)
      pdf.save(`TransactionNum${this.numTransac}.pdf`); // Generated PDF
    })
  }

  onCountryChange(m) {
    let lastDiall =  this.objetPhone .dialCode ;
    let newDiall = m.dialCode
    console.log(`LAST = ${lastDiall} ; NEW ${newDiall}`,m);
    this.element.value = this.element.value.replace(lastDiall+'',newDiall+'');
    this.telephone = this.element.value
    this.objetPhone = m;
  }
  telInputObject(m) {
    //console.log('OBJET IN', m.s)
    this.objetPhone = m.s;
  }
  element : any ;
  currenCode :string =environment.CURREN_CODE_COUNTRY;
  controle(element) {
    this.element = element ;
    element.value = element.value.replace(/[^0-9]/gi, '');
    if ((element.value || "").substr(0, this.objetPhone.dialCode.length).indexOf(this.objetPhone.dialCode.toString()) == -1) {
      if (element.value.length <= this.objetPhone.dialCode.length && (element.value || "").substr(this.objetPhone.dialCode.length).length < 1) {
        element.value = this.objetPhone.dialCode;
      }
      else {
        if ((element.value || "").substr(0, this.objetPhone.dialCode.length).indexOf(this.objetPhone.dialCode.toString()) !== -1) {
          element.value = element.value;
        }
        else {
          element.value = this.objetPhone.dialCode + element.value;
        }
      }
    }
    this.carte.telephone = element.value
  }

  /*async filtretransaction() {
    this.init_ = false
    this.rerender()
    this.init_ = false
  }*/

  reset() {
    this.init_=true
    this.telephone = null;
    // @ts-ignore
    document.getElementById("periode").value = null;
    this.date_debut = this.now;
    this.date_fin = this.now;
    this.rerender()
  }

  formatDate(date) {
    var d = date.split('/');
    return [d[2], d[0], d[1]].join('-');
  }

  async search() {
    this.init_ = false;
    let periode = $('#periode').val();
    if(periode != ''){
      let tab = periode.toString().split(' - ');
      this.date_debut = this.formatDate(tab[0]);
      this.date_fin = this.formatDate(tab[1]);
    }
    this.rerender()
  }

}
