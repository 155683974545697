import { Component, OnInit } from '@angular/core';
import Login from '../../../../model/Login';
import {HttpClient} from '@angular/common/http';
import {ActivatedRoute, Router} from '@angular/router';
import {AuthServiceService} from "../../../../service/auth-service.service";
import {ToastrService} from "ngx-toastr";
import {HelperService} from "../../../../service/helper.service";
import {Translatable} from "../../../../../Translatable";

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.css']
})
export class LoginComponent extends Translatable implements OnInit {
  currentYear: number = new Date().getFullYear();
  login : Login = new Login();
  loginBtn : boolean = false ;
  constructor(private httpClient : HttpClient,private router: Router,
              private route: ActivatedRoute,private auth:AuthServiceService,private toastr: ToastrService,private helper: HelperService) {
    super()
  }

  async ngOnInit() {
    if(await this.auth.isConnected() === 200){
      this.router.navigate(['/app'])
    }
    this.login = new Login();
  }
  async loginSubmit() {
    try {
      this.loginBtn = true ;
      let response = await this.auth.loginUser(this.login);
      if (response.code === 200) {
        this.auth.setToken(response.data.token);
        // this.loginBtn = false ;
        // return await this.router.navigate(['/app']);
        this.router.navigate(['/app']);
      } else {
        this.loginBtn = false ;
        console.log(response)
        this.toastr.error(response.msg, this.__('msg.error'));
      }
    } catch (e) {
      this.loginBtn = false ;
      this.toastr.error(this.__('msg.error_network'), this.__('msg.error'));
    }
  }

}
