import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import {AuthGuard} from './gestion/service/auth.guard';
import { BaseAppComponent } from './gestion/component/layouts/base-app/base-app.component';
import {UtilisateurComponent} from "./gestion/component/fragments/utilisateur/utilisateur.component";
import {AdminModuleComponent} from "./gestion/component/layouts/admin-module/admin-module.component";
import {HomeComponent} from "./gestion/component/layouts/home/home.component";
import {ProfilComponent} from "./gestion/component/fragments/profil/profil.component";
import {LoginComponent} from "./gestion/component/fragments/auth/login/login.component";
import {LogoutComponent} from "./gestion/component/fragments/auth/logout/logout.component";
import {ModuleComponent} from "./gestion/component/fragments/module/module.component";
import {SocieteComponent} from "./gestion/component/fragments/societe/societe.component";
import {EmployeComponent} from "./gestion/component/fragments/employe/employe.component";
import {ListeEmployeComponent} from "./gestion/component/fragments/employe/liste-employe/liste-employe.component";
import {EnrollementLotComponent} from "./gestion/component/fragments/employe/enrollement-lot/enrollement-lot.component";
import {EnrollementComponent} from "./gestion/component/fragments/employe/enrollement/enrollement.component";
import {EmployeModuleComponent} from "./gestion/component/layouts/employe-module/employe-module.component";
import {CrediterCarteComponent} from "./gestion/component/fragments/carte-employe/crediter-carte/crediter-carte.component";
import {CrediterCarteLotComponent} from "./gestion/component/fragments/carte-employe/crediter-carte-lot/crediter-carte-lot.component";
import {HistoriqueTransactionCarteComponent} from "./gestion/component/fragments/carte-employe/historique-transaction-carte/historique-transaction-carte.component";
import {CarteEmployeModuleComponent} from "./gestion/component/layouts/carte-employe-module/carte-employe-module.component";
import {VirementComponent} from "./gestion/component/fragments/virement/virement.component";
import {VirementSuiviComponent} from "./gestion/component/fragments/virement-suivi/virement-suivi.component";
import {WalletEmployeComponent} from "./gestion/component/fragments/wallet-employe/wallet-employe.component";
import {CrediterWalletComponent} from "./gestion/component/fragments/wallet-employe/crediter-wallet/crediter-wallet.component";
import {CrediterLotWalletComponent} from "./gestion/component/fragments/wallet-employe/crediter-lot-wallet/crediter-lot-wallet.component";
import {HistoriqueTransactionsComponent} from "./gestion/component/fragments/wallet-employe/historique-transactions/historique-transactions.component";
import { NotificationComponent } from './gestion/component/fragments/notification/notification.component';
import { SuiviActionComponent } from './gestion/component/fragments/suivi-action/suivi-action.component';
import {ProfilUtilisateurComponent} from "./gestion/component/fragments/profil-utilisateur/profil-utilisateur.component";

const routes: Routes = [
  {
    path : '',
    redirectTo:'app',
    pathMatch:'full'
  },{
    path : '',
    component : BaseAppComponent,
    children:[
      {
        component:HomeComponent,
        path: 'app'
      }
    ]
  },
  {
    path : 'app',
    component : BaseAppComponent,
    children: [
      {
        /*
          Dashbord pour  module admin :
        */
        path: 'admin',
        component: AdminModuleComponent,
        data: {title: 'Administartation'},
        children:[
          {
            path:"utilisateur",
            component:UtilisateurComponent,
            canActivate : [AuthGuard]
          },
          {
            path:"profil",
            component:ProfilComponent,
            canActivate : [AuthGuard]
          },
          {
            path:"module",
            component:ModuleComponent,
            canActivate : [AuthGuard]
          },
          /*{
            path:"societe",
            component:SocieteComponent,
            canActivate : [AuthGuard]
          },*/
          {
            path:"virement-history",
            component:VirementComponent,
            canActivate : [AuthGuard]
          },
          {
            path:"virement-suivi",
            component:VirementSuiviComponent,
            canActivate : [AuthGuard]
          },
          /*{
            path:"notifications",
            component:NotificationComponent,
            canActivate : [AuthGuard]
          },*/
          {
            path: 'utilisateurprofile',
            component: ProfilUtilisateurComponent,
            data: {title: 'Profile'},
            canActivate : [AuthGuard]
          },
          {
            path:"actions",
            component:SuiviActionComponent,
            canActivate : [AuthGuard]
          }
        ],
        canActivate : [AuthGuard]
      },
      {
        /*
          EMPLOYE :
        */
        path: 'employe',
        component: EmployeComponent,
        data: {title: 'Employe'},
        children:[
          {
            path:"liste-employe",
            component:ListeEmployeComponent,
            canActivate : [AuthGuard]
          },
          {
            path:"enrollement",
            component:EnrollementComponent,
            canActivate : [AuthGuard]
          },
          {
            path:"enrollement-lot",
            component:EnrollementLotComponent,
            canActivate : [AuthGuard]
          }
        ],
        canActivate : [AuthGuard]
      },
      {
        /*
          CARTE EMPLOYE :
        */
        path: 'carte-employe',
        component: CarteEmployeModuleComponent,
        data: {title: 'Carte Employe'},
        children:[
          {
            path:"crediter-carte",
            component:CrediterCarteComponent,
            canActivate : [AuthGuard]
          },
          {
            path:"crediter-lot-carte",
            component:CrediterCarteLotComponent,
            canActivate : [AuthGuard]
          },
          {
            path:"historique-transaction",
            component:HistoriqueTransactionCarteComponent,
            canActivate : [AuthGuard]
          }
        ],
        canActivate : [AuthGuard]
      },
      {
        /*
          WALLET EMPLOYE :
        */
        path: 'wallet-employe',
        component: WalletEmployeComponent,
        data: {title: 'Wallet Employe'},
        children:[
          {
            path:"crediter-wallet",
            component:CrediterWalletComponent,
            canActivate : [AuthGuard]
          },
          {
            path:"crediter-lot-wallet",
            component:CrediterLotWalletComponent,
            canActivate : [AuthGuard]
          },
          {
            path:"historique-transaction",
            component:HistoriqueTransactionsComponent,
            canActivate : [AuthGuard]
          }
        ],
        canActivate : [AuthGuard]
      },
      {
        /*
          Support :
        */
        path: 'carte-employ',
        component: AdminModuleComponent,
        data: {title: 'Reclammation'},
        children:[
          {
            path:"utilisateur",
            component:UtilisateurComponent,
            canActivate : [AuthGuard]
          }
        ],
        canActivate : [AuthGuard]
      },

    ],
    canActivate : [AuthGuard]
  },
  {
    path: 'login',
    component: LoginComponent,
    data: {
      title: 'Connexion'
    }
  },
  {
    path: 'logout',
    component: LogoutComponent,
    data: {
      title: 'Connexion'
    }
  },
  {
    path: '**',
    redirectTo: 'app/'
  }
];

@NgModule({
  imports: [RouterModule.forRoot(routes, { useHash: false,enableTracing:false })],
  exports: [RouterModule]
})

export class AppRoutingModule {
    constructor(){
    }
}
