import {Component, OnInit, TemplateRef} from '@angular/core';
import {pay_mail_virement,utilisateur} from "../../../model/db";
import {ActivatedRoute} from "@angular/router";
import {AuthServiceService} from "../../../service/auth-service.service";
import {HttpClient} from "@angular/common/http";
import {ToastrService} from "ngx-toastr";
import {HelperService} from "../../../service/helper.service";
import {environment} from "../../../../../environments/environment";
import {valuesys} from "../../../../../options";
import Swal from 'sweetalert2' ;
import {Translatable} from "../../../../Translatable";
import {DataTablesResponse} from "../../../model/DataTablesResponse";
import {BsModalRef, BsModalService} from "ngx-bootstrap";
import {NgxUiLoaderService} from "ngx-ui-loader";

@Component({
  selector: 'app-notification',
  templateUrl: './notification.component.html',
  styleUrls: ['./notification.component.css']
})
export class NotificationComponent extends Translatable implements OnInit {

  //DATATABLE

  title:string ;
  dtOptions: DataTables.Settings = {};

  titleModal:string ;
  isAdd : boolean = true ;
  modalRef: BsModalRef;
  notifications:pay_mail_virement [] = []
  notification: pay_mail_virement;
  details_notification: pay_mail_virement;
  userConnected: utilisateur;
  constructor(private activatedRoute: ActivatedRoute,
              private auth : AuthServiceService,
              private http: HttpClient,
              private toastr: ToastrService,
              private helper: HelperService,
              private modalService: BsModalService,
              private ngxService: NgxUiLoaderService
  ) {
    super();
  }
  async ngOnInit() {
    this.initDatatable()
    let res = await this.http.get<any>(environment.baseUrl+ environment.userAuth,valuesys.httpAuthOptions()).toPromise();
      if(res.code == 200){
        this.userConnected = res.data.info
    }
  }

  initDatatable(){
    this.isAdd = true ;
    this.title = this.__("societe.notification");
    this.dtOptions = this.helper.dataTableOption();
    this.dtOptions.columns = [
      {data:"prenom"},
      {data:"nom"},
      {data:"destinataire"}
    ]  ;
    this.dtOptions.ajax = (dataTablesParameters: any, callback) => {
      this.http
          .get<DataTablesResponse>(
              environment.baseUrl+ environment.notification, valuesys.httpAuthOptionsProcess($.param(dataTablesParameters))
          ).subscribe(resp => {
        this.notifications = resp.data;
        callback({
          recordsTotal: resp.recordsTotal,
          recordsFiltered: resp.recordsFiltered,
          data:[]
        });
      });
    }
  }


  close(){
    this.titleModal = "" ;
    this.modalRef.hide()
    this.details_notification = new pay_mail_virement();
  }


  async openModal(template: TemplateRef<any>,currenNotification:pay_mail_virement =null) {
    if(currenNotification == null){
      this.notification = new pay_mail_virement();
      this.isAdd = true ;

      this.titleModal = this.__('societe.notif_add') ;
    }else {
      this.isAdd = false ;
      this.titleModal = this.__('societe.notif_edit') ;
      let res = await this.http.get<any>(environment.baseUrl+ environment.notification + '?id=' + currenNotification.id,valuesys.httpAuthOptions()).toPromise();

      //this.user = res.data ;
      this.notification = currenNotification ;

    }
    this.modalRef = this.modalService.show(template,{ignoreBackdropClick:true});
  }

  async addNotifForm() {
      console.log(this.userConnected)
    if(this.isAdd ){
      console.log(this.notification);
      this.notification.pay_societe_id = this.userConnected.pay_societe_id
      // this.notification.pay_societe_id = 3
      let res:any =  await this.http.post<any>(environment.baseUrl+ environment.notification,this.notification,valuesys.httpAuthOptions()).toPromise();
      if(res.code === 201){
        this.toastr.success(res.msg,this.__("msg.success")) ;
        this.notifications.push( res.data)
        this.close()
      }
      else {
        this.toastr.error(res.msg,this.__("msg.error")) ;
      }
    }else {
      console.log(this.notification);
      let res =  await this.http.put<any>(environment.baseUrl+ environment.notification,this.notification,valuesys.httpAuthOptions()).toPromise();
      if(res.code === 201){
        this.toastr.success(res.msg,this.__("msg.success")) ;
        //this.rerender();
        this.close()
      }
      else {
        this.toastr.error(res.msg,this.__("msg.error")) ;
      }
    }
  }


  detail(showDetails: TemplateRef<any>, notification: pay_mail_virement) {
    this.titleModal = this.__("societe.notif_details");
    this.details_notification = notification
    this.modalRef = this.modalService.show(showDetails,{ignoreBackdropClick:true});
  }
}
