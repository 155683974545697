import { Component, OnInit, TemplateRef, ViewChild} from '@angular/core';
import {ActivatedRoute} from "@angular/router";
import {AuthServiceService} from "../../../service/auth-service.service";
import * as $ from  'jquery';
import Swal from 'sweetalert2' ;
import {Translatable} from "../../../../Translatable";
import {HttpClient} from "@angular/common/http";
import {ToastrService} from "ngx-toastr";
import {HelperService} from "../../../service/helper.service";
import {NgxUiLoaderService} from "ngx-ui-loader";
import {DataTablesResponse} from "../../../model/DataTablesResponse";
import {valuesys} from "../../../../../options";
import {profil, utilisateur} from "../../../model/db";
import {environment} from "../../../../../environments/environment";
import {DataTableDirective} from "angular-datatables";
import {BsModalRef, BsModalService} from "ngx-bootstrap";

@Component({
  selector: 'app-utilisateur',
  templateUrl: './utilisateur.component.html',
  styleUrls: ['./utilisateur.component.css']
})
export class UtilisateurComponent extends Translatable  implements  OnInit {
  //DATATABLE
  @ViewChild(DataTableDirective, {static: false})
  dtElement: DataTableDirective;




  title:string ;
  dtOptions: DataTables.Settings = {};
  modalRef: BsModalRef;
  titleModal:string ;
  isAdd : boolean = true ;
  isValid : boolean = false ;
  classtoogle :boolean =false ;
  objetPhone : any ;
  id_bureau_tmp : number ;
  public users :utilisateur[] = [] ;
  public user:utilisateur = new utilisateur();
  public societes:any[]=[] ;
  public profils:any [] = [new profil()];
  public user_details: any;
  public user_error : utilisateur ;
  constructor(private activatedRoute: ActivatedRoute,
              private auth : AuthServiceService,
              private http: HttpClient,
              private toastr: ToastrService,
              private helper: HelperService,
              private modalService: BsModalService,
              private ngxService: NgxUiLoaderService
  ) {
    super();
  }

  async ngOnInit() {
    this.initDatatable()
  }
  initDatatable(){
    this.isAdd = true ;
    this.isValid = false ;
    this.title = this.__("lebel_users");
    this.dtOptions = this.helper.dataTableOption();
    this.dtOptions.columns = [
      {
        "data": "prenom"
      },
      {
        "data": "nom"
      },
      {
        "data": "telephone"
      },
      {
        "data": "email"
      }
    ]
    this.dtOptions.ajax = (dataTablesParameters: any, callback) => {
      this.http
        .get<DataTablesResponse>(
          environment.baseUrl+ environment.user+"?child=profil,paySociete", valuesys.httpAuthOptionsProcess($.param(dataTablesParameters))
        ).subscribe(resp => {
        this.users = resp.data;
        callback({
          recordsTotal: resp.recordsTotal,
          recordsFiltered: resp.recordsFiltered,
          data:[]
        });
      });
    }
  }

  /*async loadBureau(){
    this.societes = await this.helper.getSelectList(environment.baseUrl+ environment.societe,['nom_societe']);
    this.id_bureau_tmp=this.user.bureau_id;
    this.user.bureau_id = null;
  }*/

  async addUserForm(){
    this.isValid = true ;
    if(this.isAdd ){
      console.log(this.user);
      let res:any =  await this.http.post<any>(environment.baseUrl+ environment.user,this.user,valuesys.httpAuthOptions()).toPromise();
      if(res.code === 201){
        this.toastr.success(res.msg,this.__("msg.success")) ;
        res.data.profil = this.profils.find( _ => _.id == this.user.profil_id) ;
        this.users.push( res.data)
        this.close()
      }else if(res.code === 400){
        //this.toastr.warning(res.data.join('<br>'),"Avvertissement") ;
        let msg = ''
        for (let item in res.data){
          msg += res.data[item][0] + "\n";
          this.user_error[item] = res.data[item][0] ;
        }
        this.toastr.warning(msg,this.__("msg.warning")) ;
      }
      else {
        this.toastr.error(res.msg,this.__("msg.error")) ;
      }
    }else {
      console.log(this.user);
      let res =  await this.http.put<any>(environment.baseUrl+ environment.user,this.user,valuesys.httpAuthOptions()).toPromise();
      if(res.code === 201){
        this.user.profil = this.profils.find((_) =>_.id == this.user.profil_id);
        this.toastr.success(res.msg,this.__("msg.success")) ;
        //this.rerender();
        this.close()
      }else if(res.code === 400){
        //this.toastr.warning(res.data.join('<br>'),"Avvertissement") ;
        let msg = ''
        for (let item in res.data){
          msg += res.data[item][0] + "\n";
          this.user_error[item] = res.data[item][0] ;
        }
        this.toastr.warning(msg,this.__("msg.warning")) ;
      }
      else {
        this.toastr.error(res.msg,this.__("msg.error")) ;
      }
    }
    this.isValid = false ;

  }
  async openModal(template: TemplateRef<any>,currenUser:utilisateur =null,elem) {

    this.user_error = new utilisateur()
    elem.disabled = true ;
    console.log(template)
    this.profils = await this.helper.getSelectList(environment.baseUrl+ environment.profil,['libelle']);
    // this.typeBureaux = await this.helper.getSelectList(environment.baseUrl+ environment.typebureau,['libelle']);
    // this.loadBureau()
    if(currenUser == null){
      this.isAdd = true ;
      this.user = new utilisateur() ;
      // this.typeBureau = new type_bureau()
      this.titleModal = this.__('label_user_new') ;
    }else {
      /*TEL*/
      this.currenCode = this.helper.getCodePaysByNum(currenUser.telephone.substr(0,3));
      /*TEL*/
      this.isAdd = false ;
      this.titleModal = this.__('label_user_edit') ;
      // let res = await this.http.get<any>(environment.baseUrl+ environment.user + '?id=' + currenUser.id+'&child=profil,paySociete',valuesys.httpAuthOptions()).toPromise();
      /*if(res.data.bureau){
        let idTypeBureaux = res.data.bureau.type_bureau_id ;
        // this.typeBureau.id = idTypeBureaux ;
        this.societes = await this.helper.getSelectList(environment.baseUrl+ environment.societe,['nom']);

      }*/
      //this.user = res.data ;
      this.user = currenUser ;

    }

    this.modalRef = this.modalService.show(template,{class:"modal-md",ignoreBackdropClick:true});
    elem.disabled = false ;
  }
  close(){

    /*if(this.user.bureau_id == null){
      this.user.bureau_id = this.id_bureau_tmp;
    }*/
    this.user = new utilisateur()
    // this.typeBureau = new type_bureau()
    this.titleModal = this.__('label_user_edit') ;
    this.modalRef.hide()
    this.user_error = new utilisateur();
  }
  delete(deletedUser:utilisateur,elm){
    elm.disabled = true
    Swal.fire({
      title: this.__('msg.confirm'),
      text: this.__('msg.warning_delete'),
      icon: 'warning',
      showCancelButton: true,
      confirmButtonText: this.__('msg.confirmed_delete'),
      cancelButtonText: this.__('msg.cancel_delete')
    }).then(async (result) => {
      if (result.value) {
        let sup = await this.http.delete<any>(environment.baseUrl+environment.user+"?id=" + deletedUser.id,valuesys.httpAuthOptions()).toPromise();
        // this.rerender();
        if(sup.code == 204){
          this.users= this.users.filter(function (item,index) {
            return item.id != deletedUser.id
          })
          Swal.fire(
            this.__('msg.deleted'),
            sup.msg,
            'success'
          )
        }else {
          this.toastr.error(sup.msg,this.__("msg.error"));
        }
        elm.disabled = false

        // For more information about handling dismissals please visit
        // https://sweetalert2.github.io/#handling-dismissals
      } else if (result.dismiss === Swal.DismissReason.cancel) {
        elm.disabled = false
        Swal.fire(
          this.__('msg.canceled'),
          this.__('msg.canceled_delete'),
          'error'
        )
      }
    })
  }

  async openModalDetails(template: TemplateRef<any>,currentUser:utilisateur =null,elm) {
    elm.disabled = true
    // this.isAdd = false ;
    this.titleModal = this.__('label_details') ;
    // let res = await this.http.get<any>(environment.baseUrl+ environment.profil,valuesys.httpAuthOptions()).toPromise();
    // this.users = res.data
    let res1 = await this.http.get<any>(environment.baseUrl+ environment.user + '?id=' + currentUser.id +"&child=profil,paySociete",valuesys.httpAuthOptions()).toPromise();
    this.user_details = res1.data ;
    console.log(this.user_details)


    this.modalRef = this.modalService.show(template,{class:"modal-lg",ignoreBackdropClick:true});
    elm.disabled = false
  }

  async refreshPassword(user: utilisateur, elm) {
    elm.disabled = true
    console.log(user)
    Swal.fire({
      title: this.__('msg.confirm'),
      text: this.__('msg.warning_regener_password'),
      icon: 'warning',
      showCancelButton: true,
      confirmButtonText: this.__('msg.confirmed__regener_password'),
      cancelButtonText: this.__('msg.cancel_regener_password')
    }).then(async (result) => {
      if (result.value) {
        let sup = await this.http.put<any>(environment.baseUrl+ environment.userRegenerePasswordListUser,{email:  user.email},valuesys.httpAuthOptions()).toPromise();
        // this.rerender();
        if(sup.code == 201){
          Swal.fire(
            this.__('msg.regered_password'),
            sup.msg,
            'success'
          )
        }else {
          this.toastr.error(sup.msg,this.__("msg.error"));
        }
        elm.disabled = false
      } else if (result.dismiss === Swal.DismissReason.cancel) {
        elm.disabled = false
        Swal.fire(
          this.__('msg.canceled'),
          this.__('msg.canceled_regered_password'),
          'error'
        )
      }
    })

  }

  async toggleState(user:utilisateur,state:number,btn:any){
    console.log(btn.hidden = true)
    //$(btn).attr('disabled');
    btn.disabled = true;
    let toogle = await this.http.get<any>(environment.baseUrl+ environment.userEdit+`?id=${user.id}&etat=${state}`,valuesys.httpAuthOptions()).toPromise();
    if(toogle.code == 201){
      this.toastr.success(toogle.msg,this.__("msg.success"));
      user.etat = toogle.data.etat

    }else {
      this.toastr.error(toogle.msg,this.__("msg.error"));
    }
    btn.disabled = false;
  }
  onCountryChange(m){
    //return false
    let lastDiall =  this.objetPhone .dialCode ;
    let newDiall = m.dialCode
    console.log(`LAST = ${lastDiall} ; NEW ${newDiall}`,m);
    this.element.value = this.element.value.replace(lastDiall+'',newDiall+'');
    this.user.telephone = this.element.value
    this.objetPhone = m ;
  }
  telInputObject(m){
    this.objetPhone = m.s ;
  }
  element : any ;
  currenCode :string =environment.CURREN_CODE_COUNTRY;
  controle(element){
    this.element = element ;
    element.value = element.value.replace(/[^0-9]/gi,'');
    if((element.value || "").substr(0,this.objetPhone.dialCode.length).indexOf(this.objetPhone.dialCode.toString()) == -1){
      if(element.value.length <=  this.objetPhone.dialCode.length  && (element.value || "").substr( this.objetPhone.dialCode.length ).length < 1)
      {
        element.value =  this.objetPhone.dialCode;
      }
      else
      {
        if((element.value || "").substr(0,this.objetPhone.dialCode.length ).indexOf( this.objetPhone.dialCode.toString()) !== -1){
          element.value =  element.value;
        }
        else {
          element.value = this.objetPhone.dialCode +  element.value;
        }
      }
    }
    this.user.telephone = element.value;
  }


}
