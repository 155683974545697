import { Component, OnInit } from '@angular/core';
import {AuthServiceService} from "../../../service/auth-service.service";
import {Translatable} from "../../../../Translatable";
import {HelperService} from "../../../service/helper.service";



@Component({
  selector: 'app-base-app',
  templateUrl: './base-app.component.html',
  styleUrls: ['./base-app.component.css']
})
export class BaseAppComponent extends Translatable implements OnInit {
  public user : any = {info:{},law:{}};
  public imgLang :string ="fr"
  public libLang :string ="fr";
  modules:any = [] ;
  basemodule:string = "/app/modules/home/";
    stateW:number;
    stateC:number;
  constructor(private auth : AuthServiceService,private helper:HelperService) {
    super();
  }

  async ngOnInit() {
    this.imgLang =  "assets/i18n/" + Translatable.getLang() + "/icon.jpg"  ;
      if(Translatable.getLang() == 'us')
          this.libLang = 'en';
      else
          this.libLang = Translatable.getLang();
      this.user = await this.auth.getLoginUser(true);
      this.stateC = this.user.info.stateWNC.carte || 1;
      this.stateW = this.user.info.stateWNC.wallet || 1;
      // this.modules = await this.auth.getMenu(this.basemodule);
      this.helper.getSoldeBureau();
  }
  changeLang(lang:string = 'us'){
    localStorage.setItem('lang', lang);
    window.location.reload();
  }
}
