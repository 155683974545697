import { Component, OnInit, TemplateRef} from '@angular/core';
import {ActivatedRoute} from "@angular/router";
import {AuthServiceService} from "../../../service/auth-service.service";
import * as $ from  'jquery';
import Swal from 'sweetalert2' ;
import {Translatable} from "../../../../Translatable";
import {HttpClient} from "@angular/common/http";
import {ToastrService} from "ngx-toastr";
import {HelperService} from "../../../service/helper.service";
import {NgxUiLoaderService} from "ngx-ui-loader";
import {DataTablesResponse} from "../../../model/DataTablesResponse";
import {valuesys} from "../../../../../options";
import {modules, profil, sous_module} from "../../../model/db";
import {environment} from "../../../../../environments/environment";
import {BsModalRef, BsModalService} from "ngx-bootstrap";

@Component({
  selector: 'app-sous-module',
  templateUrl: './sous-module.component.html',
  styleUrls: ['./sous-module.component.css']
})
export class SousModuleComponent extends Translatable implements OnInit {

  title:string ;
  dtOptions: DataTables.Settings = {};
  modalRef: BsModalRef;
  titleModal:string ;
  isAdd : boolean = true ;
  isValid : boolean = false ;
  modules : any = [] ;
  public sModules :sous_module[] = [] ;
  public sModule_:sous_module = new sous_module();
  s_module_error: sous_module;
  constructor(private activatedRoute: ActivatedRoute,
              private auth : AuthServiceService,
              private http: HttpClient,
              private toastr: ToastrService,
              private helper: HelperService,
              private modalService: BsModalService,
              private ngxService: NgxUiLoaderService
  ) {
    super();
  }


  async ngOnInit() {
    this.initDatatable()
  }
  initDatatable(){
    this.isAdd = true ;
    this.isValid = false ;
    this.title = this.__("label_sm_s_module");
    this.dtOptions = this.helper.dataTableOption();
    this.dtOptions.columns = [
      {
        "data": "libelle"
      },
      {
        "data": "code"
      },
      {
        "data": "module_id"
      }
    ]
    this.dtOptions.ajax = (dataTablesParameters: any, callback) => {
      this.http
        .get<DataTablesResponse>(
          environment.baseUrl+ environment.sModules + "?child=module", valuesys.httpAuthOptionsProcess($.param(dataTablesParameters))
        ).subscribe(resp => {
        this.sModules = resp.data;
        callback({
          recordsTotal: resp.recordsTotal,
          recordsFiltered: resp.recordsFiltered,
          data:[]
        });
      });
    }
  }



  async addForm(){
    this.isValid = true ;
    if(this.isAdd ){
      console.log(this.sModules);
      let res =  await this.http.post<any>(environment.baseUrl+ environment.sModules,this.sModule_,valuesys.httpAuthOptions()).toPromise();
      if(res.code === 201){
        this.toastr.success(res.msg,this.__("msg.success")) ;
        //this.rerender();
        res.data.module = this.modules.filter((item:modules)=>item.id == this.sModule_.module_id)[0];
        this.sModules.push(res.data)
        this.close()
      }else if(res.code === 400){
        //this.toastr.warning(res.data.join('<br>'),"Avvertissement") ;
        let msg = ''
        for (let item in res.data){
          msg += res.data[item][0] + "\n";
          this.s_module_error[item] = res.data[item][0] ;
        }
        this.toastr.warning(msg,this.__("msg.warning")) ;
      }
      else {
        this.toastr.error(res.msg,this.__("msg.error")) ;
      }
    }else {
      console.log(this.sModule_);
      let res =  await this.http.put<any>(environment.baseUrl+ environment.sModules,this.sModule_,valuesys.httpAuthOptions()).toPromise();
      if(res.code === 201){
        this.sModule_.module = this.modules.filter((item:modules)=>item.id == this.sModule_.module_id)[0];

        this.toastr.success(res.msg,this.__("msg.success")) ;
        this.close()
      }else if(res.code === 400){
        //this.toastr.warning(res.data.join('<br>'),"Avvertissement") ;
        let msg = ''
        for (let item in res.data){
          msg += res.data[item][0] + "\n";
          this.s_module_error[item] = res.data[item][0] ;
        }
        this.toastr.warning(msg,this.__("msg.warning")) ;
      }
      else {
        this.toastr.error(res.msg,this.__("msg.error")) ;
      }
    }
    this.isValid = false ;

  }
  async openModal(template: TemplateRef<any>,current:sous_module =null) {
    this.s_module_error = new sous_module();
    console.log("MODAL CONTEN",template)
    //let resp = await this.http.get<any>(environment.baseUrl+ environment.modules ,valuesys.httpAuthOptions()).toPromise();
    this.modules = await this.helper.getSelectList(environment.baseUrl+ environment.modules,['libelle'])//resp.data ;

    if(current == null){
      this.isAdd = true ;
      this.sModule_ = new sous_module() ;
      this.titleModal = this.__('s_modules.new') ;
    }else {
      this.isAdd = false ;
      this.titleModal = this.__('s_modules.edit') ;
      let res = await this.http.get<any>(environment.baseUrl+ environment.sModules + '?id=' + current.id+'&child=bureau,profil',valuesys.httpAuthOptions()).toPromise();
      this.sModule_ = current ;
    }
    this.modalRef = this.modalService.show(template,{ignoreBackdropClick:true});
  }
  close(){
    this.sModule_ = new sous_module();
    this.titleModal = '' ;
    this.modalRef.hide() ;
    this.s_module_error = new sous_module();
  }
  delete(deleted:sous_module){
    Swal.fire({
      title: this.__('msg.confirm'),
      text: this.__('msg.warning_delete'),
      icon: 'warning',
      showCancelButton: true,
      confirmButtonText: this.__('msg.confirmed_delete'),
      cancelButtonText: this.__('msg.cancel_delete')
    }).then(async (result) => {
      if (result.value) {
        let sup = await this.http.delete<any>(environment.baseUrl+ environment.sModules+"?id=" + deleted.id,valuesys.httpAuthOptions()).toPromise();
        // this.rerender();
        if(sup.code == 204){
          this.sModules= this.sModules.filter(function (item,index) {
            return item.id != deleted.id
          })
          Swal.fire(
            this.__('msg.deleted'),
            sup.msg,
            'success'
          )
        }else {
          this.toastr.error(sup.msg,this.__("msg.error"));
        }

        // For more information about handling dismissals please visit
        // https://sweetalert2.github.io/#handling-dismissals
      } else if (result.dismiss === Swal.DismissReason.cancel) {
        Swal.fire(
          this.__('msg.canceled'),
          this.__('msg.canceled_delete'),
          'error'
        )
      }
    })
  }



  async toggleState(current:sous_module,state:number,btn:any){
    console.log(btn.hidden = true)
    $(btn).attr('disabled');
    btn.disabled = true;
    let toogle = await this.http.get<any>(environment.baseUrl+ environment.sModuleEdit+`?id=${current.id}&etat=${state}`,valuesys.httpAuthOptions()).toPromise();
    if(toogle.code == 201){
      this.toastr.success(toogle.msg,this.__("msg.success"));
      current.etat = toogle.data.etat

    }else {
      this.toastr.error(toogle.msg,this.__("msg.error"));
    }
    btn.disabled = false;
  }

}
