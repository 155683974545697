export const low = {
  "sunupay": {
    "paye_administration": {
      "code": "ADM",
      "a": {
        "relev__compte_soci_t_": "ADM_1",
        "ajouter_un_profil": "ADM_2",
        "modifer_un_profil": "ADM_3",
        "supprimer_un_profil": "ADM_4",
        "lister_les_profils": "ADM_5",
        "activer_d_sactiver_un_profil": "ADM_6",
        "lister_les_affectations_des_droits": "ADM_7",
        "ajouter_une_affectation_de_droit": "ADM_8",
        "ajouter_un_utilisateur": "ADM_9",
        "modifier_un_utilisateur": "ADM_10",
        "reg_n_rer_mot_de_passe_utilisateur": "ADM_11",
        "activer_d_sactiver_un_utilisateur": "ADM_12",
        "supprimer_un_utilisateur": "ADM_13",
        "lister_les_utilisateurs": "ADM_14",
        "suivi_compte": "ADM_15"
      }
    },
    "paye_gestion_employ_s": {
      "code": "GEM",
      "a": {
        "supprimer_un_employer": "GEM_1",
        "enroler_un_employer": "GEM_2",
        "enrolement_par_lot": "GEM_3",
        "liste_des_employers": "GEM_4",
        "activer_desactiver_un_employer": "GEM_5"
      }
    },
    "paye_gestion_cartes": {
      "code": "GCA",
      "a": {
        "consulter_solde_carte": "GCA_1",
        "recharger_carte": "GCA_2",
        "rechargement_carte_par_lot": "GCA_3",
        "historique_des_transactions": "GCA_4"
      }
    },
    "paye_gestion_wallet": {
      "code": "GWA",
      "a": {
        "details_wallet": "GWA_1",
        "rechargement_wallet": "GWA_2",
        "rechargement_wallet_par_lot": "GWA_3",
        "historique_des_transactions": "GWA_4"
      }
    }
  }
};
