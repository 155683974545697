declare var require: any;
import {low} from "../assets/i18n/low";

export class Translatable {

    static data : any = {};
    static lang :any = {} ;
    private lang :any;
    constructor(){

        if(localStorage.getItem('lang') == 'fr'){
            Translatable.data = require('../assets/i18n/fr/lang.json');
            Translatable.lang.current = 'fr';
        } else if(localStorage.getItem('lang') == 'us'){
            Translatable.data = require('../assets/i18n/us/lang.json');
            Translatable.lang.current = 'us';
        }
        else if(!Translatable.lang.current){
            let lang = localStorage.getItem('lang') || "fr";

            if(!['fr', 'us'].includes(lang)){
                lang = 'us';
            }

            Translatable.lang.current = lang;
            localStorage.setItem('lang', lang);

            Translatable.data = require('../assets/i18n/'+lang+'/lang.json');
        }

        this.lang = Translatable.lang;
    }

    static getLang(){
        return (new Translatable()).getLang().current;
    }

    public __(key) {
        try {
            let path = `Translatable.data.${key} || '${key}'`;
            //  console.log("AAZ",path)
            return eval(path);
        } catch (e) {
            return key ;
        }
    }


    getLang(){
        return this.lang;
    }

    _code_(code){
        let __ = low ;
        let path = `__.${code}`;
        try {
            return  eval(path)
        } catch (e) {
            console.log(e)
            return path + "no exist"
        }
    }

}
