import { BrowserModule } from '@angular/platform-browser';
import {LOCALE_ID, NgModule} from '@angular/core';
import { DataTablesModule } from 'angular-datatables';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { NgSelect2Module } from 'ng-select2';
import {Ng2TelInputModule} from 'ng2-tel-input';
import { registerLocaleData } from '@angular/common';
import localeFR from '@angular/common/locales/fr';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';
import {HTTP_INTERCEPTORS, HttpClientModule} from '@angular/common/http';
import {AuthGuard} from './gestion/service/auth.guard';
import { BaseAppComponent } from './gestion/component/layouts/base-app/base-app.component';
import { AdminSideBarComponent } from './gestion/component/controls/admin-side-bar/admin-side-bar.component';
import {AuthServiceService as AuthService} from "./gestion/service/auth-service.service";
import { UtilisateurComponent } from './gestion/component/fragments/utilisateur/utilisateur.component';
import { AdminModuleComponent } from './gestion/component/layouts/admin-module/admin-module.component';
import { ModuleComponent } from './gestion/component/fragments/module/module.component';
import { ProfilComponent } from './gestion/component/fragments/profil/profil.component';
import { SousModuleComponent } from './gestion/component/fragments/sous-module/sous-module.component';
import { ToastrModule } from 'ngx-toastr';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import {HelperService} from "./gestion/service/helper.service";
import { BsDatepickerModule } from 'ngx-bootstrap/datepicker';
import {
  NgxUiLoaderConfig,
  NgxUiLoaderHttpModule,
  NgxUiLoaderModule,
  NgxUiLoaderRouterModule, PB_DIRECTION,
  POSITION, SPINNER
} from 'ngx-ui-loader';
import { ChartsModule } from 'ng2-charts';
import { MomentModule } from 'ngx-moment';
import { MaskNumberPipe } from './mask-number.pipe';
//import { TooltipModule } from 'ng2-tooltip-directive';
import { ProfilUtilisateurComponent } from './gestion/component/fragments/profil-utilisateur/profil-utilisateur.component';
import { HomeComponent } from './gestion/component/layouts/home/home.component';
import {LoginComponent} from "./gestion/component/fragments/auth/login/login.component";
import {LogoutComponent} from "./gestion/component/fragments/auth/logout/logout.component";
import { SocieteComponent } from './gestion/component/fragments/societe/societe.component';
import { EmployeSideBarComponent } from './gestion/component/controls/employe-side-bar/employe-side-bar.component';
import { CarteEmployeSideBarComponent } from './gestion/component/controls/carte-employe-side-bar/carte-employe-side-bar.component';
import { EmployeComponent } from './gestion/component/fragments/employe/employe.component';
import { ListeEmployeComponent } from './gestion/component/fragments/employe/liste-employe/liste-employe.component';
import { EnrollementComponent } from './gestion/component/fragments/employe/enrollement/enrollement.component';
import { EnrollementLotComponent } from './gestion/component/fragments/employe/enrollement-lot/enrollement-lot.component';
import { EmployeModuleComponent } from './gestion/component/layouts/employe-module/employe-module.component';
import { CarteEmployeComponent } from './gestion/component/fragments/carte-employe/carte-employe.component';
import { CrediterCarteComponent } from './gestion/component/fragments/carte-employe/crediter-carte/crediter-carte.component';
import { CrediterCarteLotComponent } from './gestion/component/fragments/carte-employe/crediter-carte-lot/crediter-carte-lot.component';
import { HistoriqueTransactionCarteComponent } from './gestion/component/fragments/carte-employe/historique-transaction-carte/historique-transaction-carte.component';
import { CarteEmployeModuleComponent } from './gestion/component/layouts/carte-employe-module/carte-employe-module.component';
import { VirementComponent } from './gestion/component/fragments/virement/virement.component';
import { VirementSuiviComponent } from './gestion/component/fragments/virement-suivi/virement-suivi.component';
import { WalletEmployeComponent } from './gestion/component/fragments/wallet-employe/wallet-employe.component';
import { CrediterWalletComponent } from './gestion/component/fragments/wallet-employe/crediter-wallet/crediter-wallet.component';
import { CrediterLotWalletComponent } from './gestion/component/fragments/wallet-employe/crediter-lot-wallet/crediter-lot-wallet.component';
import { HistoriqueTransactionsComponent } from './gestion/component/fragments/wallet-employe/historique-transactions/historique-transactions.component';
import { WalletEmployeSideBarComponent } from './gestion/component/controls/wallet-employe-side-bar/wallet-employe-side-bar.component';
import { WalletEmployeModuleComponent } from './gestion/component/layouts/wallet-employe-module/wallet-employe-module.component';
import { NotificationComponent } from './gestion/component/fragments/notification/notification.component';
import { SuiviActionComponent } from './gestion/component/fragments/suivi-action/suivi-action.component';
import {AuthInterceptor} from "./gestion/service/auth-interceptor";
import {ModalModule} from "ngx-bootstrap";
import {TooltipModule} from "ngx-bootstrap";


registerLocaleData(localeFR);
const ngxUiLoaderConfig: NgxUiLoaderConfig = {
  bgsColor: '##00acc1',
  fgsColor: '##00acc1',
  bgsPosition: POSITION.centerCenter,
  // bgsOpacity:1,
  bgsSize: 70,
  fgsSize: 30,
  bgsType: SPINNER.rectangleBouncePulseOutRapid, // background spinner type|rectangleBounce|ballScaleMultiple|cubeGrid
  fgsType: SPINNER.circle, // foreground spinner type | ballScaleMultiple
  pbDirection: PB_DIRECTION.leftToRight, // progress bar direction
  pbThickness: 7, // progress bar thickness
};

@NgModule({
  declarations: [
    AppComponent,
    BaseAppComponent,
    AdminSideBarComponent,
    UtilisateurComponent,
    AdminModuleComponent,
    ModuleComponent,
    ProfilComponent,
    SousModuleComponent,
    MaskNumberPipe,
    ProfilUtilisateurComponent,
    HomeComponent,
    LoginComponent,
    LogoutComponent,
    SocieteComponent,
    EmployeSideBarComponent,
    CarteEmployeSideBarComponent,
    EmployeComponent,
    ListeEmployeComponent,
    EnrollementComponent,
    EnrollementLotComponent,
    EmployeModuleComponent,
    CarteEmployeComponent,
    CrediterCarteComponent,
    CrediterCarteLotComponent,
    HistoriqueTransactionCarteComponent,
    CarteEmployeModuleComponent,
    VirementComponent,
    VirementSuiviComponent,
    WalletEmployeComponent,
    CrediterWalletComponent,
    CrediterLotWalletComponent,
    HistoriqueTransactionsComponent,
    WalletEmployeSideBarComponent,
    WalletEmployeModuleComponent,
    NotificationComponent,
    SuiviActionComponent
  ],
  imports: [
    MomentModule.forRoot({
      relativeTimeThresholdOptions: {
        'm': 59
      }
    }),
    BrowserModule,
    AppRoutingModule,
    FormsModule,
    ReactiveFormsModule,
    HttpClientModule,
    DataTablesModule,
    BrowserAnimationsModule, // required animations module
    ToastrModule.forRoot({
      timeOut: 10000,
      positionClass: 'toast-top-right',
      preventDuplicates: true,
      progressBar: true
    }),
    NgxUiLoaderModule.forRoot(ngxUiLoaderConfig),
    NgxUiLoaderHttpModule.forRoot({exclude: ['/login']}),
    NgSelect2Module,
    Ng2TelInputModule,
    ChartsModule,
    BsDatepickerModule.forRoot(),
    ModalModule.forRoot(),
    TooltipModule.forRoot()



  ],
  providers: [
    AuthGuard,
    AuthService,
    HelperService,
    { provide: LOCALE_ID, useValue: 'fr' },
    {
      // use fake backend in place of Http service for backend-less development
      provide: HTTP_INTERCEPTORS,
      useClass: AuthInterceptor,
      multi: true
    }


  ],
  bootstrap: [AppComponent]
})
export class AppModule {}
