/* tslint:disable */


/**
 * AUTO-GENERATED FILE @ 2019-11-18 13:08:33 - DO NOT EDIT!
 *
 * This file was automatically generated by schemats v.3.0.3
 * $ schemats generate -c mysql://username:password@local.numherit-local.com/phco_preprod -t action_api -t action_utilisateur -t affectation_droit -t alert_interdit -t api_sms -t bureau -t carte -t cartestock -t code_charge -t code_retrait -t compte -t cumul_transaction -t demande_approvisionnement -t dist_point_service -t dist_releve_solde_commission -t dist_repartition_commission -t dist_transaction -t document -t droit -t lotcarte -t marchand -t mar_affectation_caisse -t mar_appels_fond -t mar_caisse -t mar_releve_compte -t mar_transaction -t module -t nationalite -t notification -t pays -t periode -t profession -t profil -t region -t regle -t releve_compte -t releve_compte_bureau -t releve_compte_service -t releve_wallet -t service -t sous_module -t tarif_frais -t transaction -t transaction_api -t transfert -t typepiece -t type_bureau -t type_carte -t type_notification -t type_profil -t type_transaction -t utilisateur -t virement -t wallet -s phco_preprod
 *
 */


export class action_api {
   id : number=0;
   url : string;
   parametres : string | null;
   type : string | null;
   code_retour : number | null;
   response : string | null;
   transaction_id : number;
   date : Date;
  etat : number | null;
}



export class action_utilisateur {
   id : number=0;
   utilisateur_id : number;
   etat : number | null;
   module_id: number;
   date : Date;
   action : string | null;
   request_url : string | null;
   code : number | null;
   msg : string | null;
}

export class response {
   code: number | null;
   msg: string | null;
}


export class affectation_droit {
   id : number=0;
   profil_id : number;
   droit_id : number;
   etat : number | null;

}



export class alert_interdit {
   id : number=0;
   nom_complet : string;
   date_naissance : Date;
   code : string;
   interdit : string;
   statut : number | null;
   date_crea : Date;
   piece : string | null;
   adresse : string | null;
   typepiece_id : number;
   utilisateur_id : number;
   etat : number | null;
  typepiece? :typepiece ;
  utilisateur? :utilisateur ;

}

export class interdits {
  id : number=0;
  nom : string;
  prenom : string;
  wholename : string;
  date_nais : Date;
  date_fin : Date;
  code : string;
  identifiant : string;
  statut : number | null;
  type_piece : string | null;
  nationalite : string | null;
  numero_piece : string | null;
  id_paper_details : string | null;
  etat : number | null;



}



export class api_sms {
   id : number=0;
   fournisseur : string;
   token : string | null;
   date_expiration : Date | null;
   etat : number | null;

}



export class bureau {
   id : number=0;
   nom : string;
   adresse : string | null;
   solde : number;
   etat : number | null;
   code : string | null;
   longitude : number | null;
   latitude : number | null;
   region_id : number;
   stock_carte_jula : number | null;
   stock_carte_visa : number | null;
   type_bureau_id : number;
   commission_dist : number | null;
   responsable_id:number;
  typeBureau? :type_bureau ;
  responsable? :utilisateur ;
  region? :region ;

}



export class carte {
   id : number=0;
   prenom : string;
   nom : string;
   date_naissance : Date | null;
   piece : string | null;
   adresse : string | null;
   telephone : string;
   email : string | null;
   etat : number | null;
   numero_carte : string | null;
   numero_serie : string | null;
   date_expiration : Date | null;
   date_activation : Date | null;
   wallet_id : number | null;
   type_carte_id : number | null;
   nationalite_id : number | null;
   profession_id : number | null;
   pays_id : number;
   region_id : number;
   typepiece_id : number;
  date_delivrance : number;
  code_postale : string;
  telephone_fixe : string;
  embossage : string;
  commentaire : string;
  sexe : string;
  pay?: pays [] | null;
  region?: region [] | null;
  typeCarte?: type_carte [] | null;
  profession?: profession [] | null;
  wallet?: wallet [] | null;
  typepiece?: typepiece [] | null;


}



export class cartestock {
   id : number=0;
   num_serie : string;
   niveau : string | null;
   typelot : string | null;
   etatvente : number | null;
   datevente : Date | null;
   commentaire : string | null;
   type_carte_id : number;
   bureau_id : number;
   lotcarte_id : number;
  etat : number | null;
}



export class code_charge {
   id : number=0;
   code : number;
   statut : number;
   date : Date | null;
   bureau_id : number;
   numcarte : string | null;
   montant : number | null;
  etat : number | null;
}



export class code_retrait {
   id : number=0;
   code : number;
   statut : number;
   date : Date;
   numcarte : string;
   cni : string;
   montant : number | null;
   bureau_id : number;
   etat : number | null;
}



export class compte {
   id : number=0;
   nom : string;
   solde : number | null;
  etat : number | null;
}



export class cumul_transaction {
   id : number=0;
   solde_day : number | null;
   solde_mois : number | null;
   solde_annee : number | null;
   date_t : Date | null;
   type_transaction_id : number;
   wallet_id : number;
  etat : number | null;
}



export class demande_approvisionnement {
   id : number=0;
   user_crea : number;
   bureau_id : number;
   montant : number;
   date_crea : Date;
   user_valide : number | null;
   date_validation : Date | null;
   statut : number | null;
   commentaire : string | null;
  etat : number | null;
}



export class dist_point_service {
   id : number=0;
   nom : string;
   solde : number | null;
   etat : number | null;
   bureau_id : number;

}



export class dist_releve_solde_commission {
   id : number=0;
   solde_avant : number | null;
   solde_apres : number | null;
   date : Date | null;
   montant : string | null;
   type : number | null;
   commentaire : string | null;
   bureau_id : number;
   service_id : number | null;
  etat : number | null;
}



export class dist_repartition_commission {
   id : number=0;
   taux : number | null;
   bureau_id : number;
   service_id : number;
  etat : number | null;
}



export class dist_transaction {
   id : number=0;
   service_id : number;
   wallet_id : number | null;
   datetransac : Date;
   numtransac : string;
   montant : number | null;
   provenance : number;
   carte_id : number | null;
   commentaire : string | null;
   statut : number | null;
   commission : number | null;
   dist_point_service_id : number;
   bureau_id : number;
  etat : number | null;
}



export class document {
   id : number=0;
   libelle : string | null;
   wallet_id : number;
  etat : number | null;
}



export class droit {
   id : number=0;
   libelle : string;
   etat : number | null;
   sous_module_id : number;
   controller : string;
   action : string;
   espace : string;

}



export class lotcarte {
   id : number=0;
   reference : string | null;
   numero_debut : string;
   numero_fin : string;
   stock : number;
   stock_initial : number;
   stock_avant : number;
   stock_apres : number;
   typelot : number;
   etat : number | null;
   type_carte_id : number;
   bureausource_id : number;
   bureaudestinataire_id : number | null;
   lotcarte_id : number | null;
   typeCarte?:type_carte | null ;
   bureauDestinatire?:bureau | null ;
   date_add : Date;
   date_modif : Date;

}



export class marchand {
   id : number=0;
   nom_marchand : string;
   rc : string | null;
   ninea : string | null;
   adresse : string | null;
   telephone_fixe : string | null;
   email : string;
   tel_mobile : string;
   etat : number | null;
   user_creation : number;
   date_creation : Date | null;
   cle : string | null;
   nom_responsable : string | null;
   prenom_responsable : string | null;
   solde : number | null;
   type : number | null;

}



export class mar_affectation_caisse {
   id : number=0;
   date_affect : Date | null;
   heure_debut : string | null;
   heure_fin : string | null;
   caisse_id : number;
   utilisateur_id : number;
  etat : number | null;
}



export class mar_appels_fond {
   id : number=0;
   date : Date;
   montant : number;
   etat : number | null;
   statut : number | null;
   date_validation : Date | null;
   marchand_id : number;
   user_valide_id : number | null;
   user_marchand_id : number;
  marchand?:marchand | null ;

}



export class mar_caisse {
   id : number=0;
   codemarchand : string;
   numcaisse : string | null;
   user_creation : number | null;
   date_creation : Date | null;
   user_modification : number | null;
   date_modification : Date | null;
   etat : number | null;
   marchand_id : number;

}



export class mar_releve_compte {
   id : number=0;
   num_transac : string;
   solde_avant : number;
   solde_apres : number;
   montant : number;
   date_transaction : Date;
   marchand_id : number;
   etat : number | null;
}



export class mar_transaction {
   id : number=0;
   date_transaction : Date;
   num_transaction : string;
   montant : number;
   statut : number;
   commentaire : string | null;
   marchand_id : number;
  etat : number | null;
}



export class modules {
   id : number=0;
   libelle : string;
   icon : string | null;
   etat : number | null;
   code : string;

}



export class nationalite {
   id : number=0;
   libelle : string;
   etat : number | null;

}



export class notification {
   id : number=0;
   objet : string;
   message : string;
   expediteur : string | null;
   destinataire : string | null;
   etat : number | null;
   type_notification_id : number;

}



export class pays {
   id : number=0;
   nom : string | null;
   code : string;
   etat : number | null;

}



export class periode {
   id : number=0;
   libelle : string;
  etat : number | null;
}



export class profession {
   id : number=0;
   libelle : string;
   etat : number | null;

}



export class profil {
   id : number=0;
   libelle : string;
   etat : number | null;
  marchand_id : number | null;
  code : string | null;
   type_profil_id : number | null;
   bureau_id : number | null;
   typeProfil? : type_profil | null;

}



export class region {
   id : number=0;
   nom : string | null;
   code : string;
   etat : number | null;
   pays_id : number;


}



export class regle {
   id : number=0;
   limite_montant : number;
   commentaire : string | null;
   etat : number | null;
   identite : string | null;
   date_creation : Date | null;
   periode_id : number;
   type_transaction_id : number;

}



export class releve_compte {
   id : number=0;
   compte_id : number;
   solde_avant : number | null;
   solde_apres : number | null;
   date : Date | null;
   montant : string | null;
   type : number | null;
   commentaire : string | null;
  etat : number | null;
}



export class releve_compte_bureau {
   id : number=0;
   solde_avant : number | null;
   solde_apres : number | null;
   date : Date | null;
   montant : string | null;
   type : number | null;
   commentaire : string | null;
   bureau_id : number;
  etat : number | null;
}



export class releve_compte_service {
   id : number=0;
   solde_avant : number | null;
   solde_apres : number | null;
   date : Date | null;
   montant : string | null;
   type : number | null;
   commentaire : string | null;
   service_id : number;
   etat : number | null;
}



export class releve_wallet {
   id : number=0;
   solde_avant : number;
   solde_apres : number;
   date : Date;
   montant : string;
   type : number;
   commentaire : string | null;
   wallet_id : number;
  etat : number | null;
}



export class service {
   id : number=0;
   libelle : string;
   frais : number | null;
   etat : number | null;
   solde : number | null;
  /* tva : number | null = 0 ;*/
   etat_tva : number | null = 0 ;
   solde_commission : number | null;
   code_service:string;

}



export class sous_module {
   id : number=0;
   libelle : string;
   icon : string | null;
   etat : number | null;
   code : string;
   module_id : number;
   module?:modules

}



export class tarif_frais {
   id : number=0;
   montant_deb : number = 0;
   montant_fin : number =0;
   ht : number | null;
   /*tva : number | null;
   valeur : number;*/
   service_id : number;
  etat : number | null;
}



export class transaction {
   id : number=0;
   service_id : number;
   wallet_id : number | null;
   utilisateur_id : number | null;
   datetransac : Date;
   shortDate : string;
   numtransac : string;
   montant : number | null;
   provenance : number;
   carte_id : number | null;
   commentaire : string | null;
   statut : number | null;
   commission : number | null;
   bureau_id : number;
  bureau :bureau|null ;
  carte :carte|null ;
  service :service|null ;
  utilisateur :utilisateur|null ;
  wallet :wallet|null ;
  etat : number | null;
}



export class transaction_api {
   id : number=0;
   date_transaction : Date;
   num_transaction : string;
   montant_transaction : number;
   status_transaction : number;
   email_acheteur : string | null;
   num_transac_marchand : string | null;
   moyen_paiement : number;
   url_retour : string | null;
   url_cancel : string | null;
   url_notification : string | null;
   commentaire : string | null;
  etat : number | null;
}



export class transfert {
   id : number=0;
   num_transaction : string;
   code_transfert : string;
   montant : number;
   frais : number;
   montant_total : number;
   date_transfert : Date;
   prenom_sender : string;
   nom_sender : string;
   piece_sender : string;
   tel_sender : string;
   adresse_sender : string | null;
   typepiecesender_id : number;
   paysender_id : number;
   regionsender_id : number;
   prenom_receiver : string;
   nom_receiver : string;
   tel_receiver : string;
   adresse_receiver : string | null;
   piece_receiver : string | null;
   typepiecereceiver_id : number | null;
   paysreceiver_id : number;
   regionreceiver_id : number;
   statut : number;
   utilisateursender_id : number;
   date_send : Date;
   utilisateureceiver_id : number | null;
   date_receive : Date | null;
   date_expire_receiver : Date | null;
   date_delivrance_receiver : Date | null;
   service_id : number;
   bureauSender?:bureau | null ;
   bureauRecever?:bureau | null ;
   bureau_recever_id:number;
   bureau_sender_id:number;
   utilisateurRecever?:utilisateur|null ;
   utilisateurSender?:utilisateur|null ;
  payRecever:pays|null ;
  paySender:pays|null ;
  typepieceSender:typepiece|null ;
  typepieceRecever:typepiece|null ;
  etat : number | null;

}



export class typepiece {
   id : number=0;
   libelle : string;
   etat : number | null;
   wallets?: wallet[] | null

}



export class type_bureau {
   id : number=0;
   libelle : string;
   etat : number | null;

}



export class type_carte {
   id : number=0;
   libelle : string;
   montant : number;
   montantmaxdepot : number;
   montantmaxretrait : number;
   nbremaxretrait : number;
   nbremaxdepot : number;
  etat : number | null;
}



export class type_notification {
   id : number=0;
   libelle : string | null;
   etat : number | null;

}



export class type_profil {
   id : number=0;
   libelle : string;
   etat : number | null;

}



export class type_transaction {
   id : number=0;
   libelle : string;
  etat : number | null;
}



export class utilisateur {
   id : number=0;
   prenom : string;
   nom : string;
   telephone : string | null;
   email : string;
   password : string;
   login : string;
   admin : number;
   etat : number | null;
   first_connect : number | null;
   profil_id : number;
   bureau_id : number | null;
   responsable : number | null;
   connect : number | null;
   dist_point_service_id : number | null;
   marchand_id : number | null;
   token : string | null;
   pay_societe_id : number | null;
   profil? : profil | null;
  societe? : pay_societe | null;



}



export class virement {
   id : number=0;
   montant : number;
   date_crea : Date;
   statut : number;
   user_crea : number;
   user_valide : number | null;
   date_validation : string | null;
   compte_id : number;
  etat : number | null;
}



export class wallet {
   id : number=0;
   prenom : string;
   nom : string;
   date_naissance : Date | null;
   piece : string | null;
   adresse : string | null;
   telephone : string |  null = null;
   email : string | null;
   solde : number | null;
   etat : number | null;
   password : string;
   clesecrete : number;
   identification : number;
   typepiece_id?: number | null;
   typepiece?: typepiece | null;
   cartes?: carte [] | null

}

export class profilage_module {
    id : number;
    sous_modules : profilage_sous_module[] = [];
    libelle : string;
    icon : string;
    code : string;
   etat : number | null;
}

export class profilage_sous_module{
    id : number;
    droits : profilage_droit[] = [];
    libelle : string;
    icon : string;
    code : string;
   etat : number | null;
}

export class profilage_droit{
    id : number;
    etat : number;
    libelle : string;
    exist : number;
}
export class stock_general {
   type_carte_id : number;
  numero_debut : number;
  numero_fin : number;
  bureaudestinataire_id? : number |null;
  id_lot? : number |null;
  etat : number | null;
}

export class serviceTotal{
    nom:  string;
    frais:   number;
    montant: number;
    montantTTC: number;
    etat : number | null;
    nombre: number;
 }

 export class parametrage_interdits{
   id:  number;
   prenom:  number;
   prenom1:  number;
   nom:  number;
   date_nais:  number;
   user_modif: number;
   date_modif: Date | null;
   etat : number | null;
 }

 export class year{
   id: number;
   text: string;
 }

 export class totaux {
   Tfrais:   number;
   Tmontant: number;
   TmontantTTC: number;
   Tnombre: number;
 }

                                        //  LES CLASSES DU DB DE SUNU PAY
export class pay_comptes {
  id : number;
  solde : number;
  statut : boolean;
  client : number | null;
  pay_societe_id : number;
  paySociete : pay_societe | null;

}



export class pay_employe {
  id : number;
  prenom : string | null;
  nom : string | null;
  adresse : string | null;
  telephone : string | null;
  email : string | null;
  sexe : string | null;
  age : string | null;
  cni : string | null;
  matricule : string | null;
  carte_id : number | null;
  wallet_id : number | null;
  pay_societe_id : number;
  etat : boolean;
  numserie : string | null;
  motif : string | null;
  carte: carte | null ;
  wallet: wallet | null ;
  paySociete : pay_societe |null;

}



export class pay_employe_rejete {
  id : number;
  prenom : string | null;
  nom : string | null;
  adresse : string | null;
  telephone : string | null;
  email : string | null;
  sexe : string | null;
  age : string | null;
  cni : string | null;
  matricule : string | null;
  carte_id : number | null;
  pay_societe_id : number;
  etat : boolean;
  numserie : string | null;
  motif : string | null;
  carte: carte | null ;
  paySociete : pay_societe |null;

}



export class pay_mail_virement {
  id : number;
  message : string | null;
  destinataire : string | null;
  prenom : string | null;
  nom : string | null;
  type : number | null;
  pay_societe_id : number;
  paySociete : pay_societe |null;

}



export class pay_releve_compte_societe {
  id : number;
  num_transac : string;
  date_transaction : Date;
  solde_avant : number;
  solde_apres : number;
  montant : number;
  pay_societe_id : number;
  operation : string;
  commentaire : string;
  paySociete : pay_societe |null;

}



export class pay_societe {
  id : number;
  nom_societe : string;
  adresse : string | null;
  telephone : string | null;
  raison_sociale : string | null;
  email : string | null;
  capital : number | null;
  rc : string | null;
  ninea : string | null;
  site_web : string | null;
  logo : string | null;
  extension: string | null;
  etat : boolean;
  taux : number;
  payComptes :pay_comptes []|null;
  payEmployes : pay_employe[]|null
  payEmployeRejetes: pay_employe_rejete[]|null;
  payMailVirements:pay_mail_virement[]|null;
  payReleveCompteSocietes:pay_releve_compte_societe[]|null
  payTraitementFichiers:pay_traitement_fichier[]|null
  payTransactions:pay_transaction[]|null
  payVirements:pay_virement[]|null

}



export class pay_traitement_fichier {
  id : number;
  nom_fichier : string;
  date_traitement : Date;
  statut_traitement : string;
  commentaire : string | null;
  utilisateur_traitement : number;
  pay_societe_id : number;
  paySociete:pay_societe|null
  utilisateur : utilisateur | null;

}



export class pay_transaction {
  id : number;
  num_transac : string | null;
  montant : number | null;
  date_transaction : string | null;
  commentaire : string | null;
  statut : boolean | null;
  carte_id : number;
  pay_societe_id : number;
  utilisateur_id : number;
  date_validation : Date | null;
  utilisateur_validation_id : number | null;
  carte : carte | null;
  pay_societe: pay_societe | null
  utilisateur:utilisateur | null
  utilisateurValidation : utilisateur | null

}



export class pay_virement {
  id : number;
  montant : number;
  taux : number;
  montant_reel : number;
  montant_com : number;
  montant_ttc : number;
  date_virement : Date;
  statut : number;
  typevirement : boolean;
  utilisateur_crea_id : number;
  valideur : number | null;
  date_validation : Date | null;
  pay_societe_id : number;
  paySociete: pay_societe | null
  utilisateur:utilisateur | null

}


export class devise {
    id : number | null;
    nom : string;
    etat : number;
    signe : string | null;
    code : string | null;
    valeur: string | null;
    prioritaire : number | null;
    utilisateur_id : number | null;
    date_creation : string | null;
    utilisateur_modify : number | null;
    date_modify : string | null;

}
