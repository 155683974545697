import { Component, OnInit, TemplateRef, ViewChild} from '@angular/core';
import {ActivatedRoute} from "@angular/router";
import {AuthServiceService} from "../../../service/auth-service.service";
import * as $ from  'jquery';
import Swal from 'sweetalert2' ;
import {Translatable} from "../../../../Translatable";
import {HttpClient} from "@angular/common/http";
import {ToastrService} from "ngx-toastr";
import {HelperService} from "../../../service/helper.service";
import {BsModalRef, BsModalService} from "ngx-bootstrap";
import {NgxUiLoaderService} from "ngx-ui-loader";
import {DataTablesResponse} from "../../../model/DataTablesResponse";
import {valuesys} from "../../../../../options";
import {profil,pay_societe} from "../../../model/db";
import {environment} from "../../../../../environments/environment";
import { Subject } from 'rxjs';
import {DataTableDirective} from "angular-datatables";
import { NgSelect2Component } from 'ng-select2';

@Component({
  selector: 'app-societe',
  templateUrl: './societe.component.html',
  styleUrls: ['./societe.component.css']
})
export class SocieteComponent extends Translatable  implements  OnInit {
  //DATATABLE
  @ViewChild(DataTableDirective, {static: false})
  dtElement: DataTableDirective;

  title:string ;
  dtOptions: DataTables.Settings = {};
  modalRef: BsModalRef;
  titleModal:string ;
  isAdd : boolean = true ;
  isValid : boolean = false ;
  classtoogle :boolean =false ;
  objetPhone : any ;
  id_bureau_tmp : number ;
  element : any ;
  currenCode :string =environment.CURREN_CODE_COUNTRY;
  picFile: any;
  picFileOrigine: any;
  picFileExtension: any;
  getFile: boolean = true
  public societes :pay_societe[] = [] ;
  public societe:pay_societe = new pay_societe();
  public profils:any [] = [new profil()];
  public societe_details: any;
  public societe_error : pay_societe ;
  constructor(private activatedRoute: ActivatedRoute,
              private auth : AuthServiceService,
              private http: HttpClient,
              private toastr: ToastrService,
              private helper: HelperService,
              private modalService: BsModalService,
              private ngxService: NgxUiLoaderService
  ) {
    super();
  }

  async ngOnInit() {
    this.initDatatable()
  }
  initDatatable(){
    this.isAdd = true ;
    this.isValid = false ;
    this.title = this.__("label_sm_societe");
    this.dtOptions = this.helper.dataTableOption();
    this.dtOptions.columns = [
      {
        "data": "nom_societe"
      },
      {
        "data": "adresse"
      },
      {
        "data": "raison_sociale"
      },
      {
        "data": "telephone"
      },
      {
        "data": "capital"
      }
    ]
    this.dtOptions.ajax = (dataTablesParameters: any, callback) => {
      this.http
        .get<DataTablesResponse>(
          environment.baseUrl+ environment.societe, valuesys.httpAuthOptionsProcess($.param(dataTablesParameters))
        ).subscribe(resp => {
        this.societes = resp.data;
        callback({
          recordsTotal: resp.recordsTotal,
          recordsFiltered: resp.recordsFiltered,
          data:[]
        });
      });
    }
  }



  async addSocieteForm(){
    this.isValid = true ;
    if(this.isAdd ){
      this.societe.logo = this.picFile
      this.societe.extension = this.picFileExtension
      console.log(this.societe);
      this.societe.logo = this.picFile
      let res:any =  await this.http.post<any>(environment.baseUrl+ environment.societe,this.societe,valuesys.httpAuthOptions()).toPromise();
      if(res.code === 201){
        this.toastr.success(res.msg,this.__("msg.success")) ;

        this.societes.push( res.data)
        this.close()
      }else if(res.code === 400){
        //this.toastr.warning(res.data.join('<br>'),"Avvertissement") ;
        let msg = ''
        for (let item in res.data){
          msg += res.data[item][0] + "\n";
          this.societe_error[item] = res.data[item][0] ;
        }
        this.toastr.warning(msg,this.__("msg.warning")) ;
      }
      else {
        this.toastr.error(res.msg,this.__("msg.error")) ;
      }
    }else {
      console.log(this.societe);
      let res =  await this.http.put<any>(environment.baseUrl+ environment.societe,this.societe,valuesys.httpAuthOptions()).toPromise();
      if(res.code === 201){
        this.toastr.success(res.msg,this.__("msg.success")) ;

        this.close()
      }else if(res.code === 400){

        let msg = ''
        for (let item in res.data){
          msg += res.data[item][0] + "\n";
          this.societe_error[item] = res.data[item][0] ;
        }
        this.toastr.warning(msg,this.__("msg.warning")) ;
      }
      else {
        this.toastr.error(res.msg,this.__("msg.error")) ;
      }
    }
    this.isValid = false ;

  }
  async openModal(template: TemplateRef<any>,currentSociete:pay_societe =null,elem) {

    this.societe_error = new pay_societe()
    elem.disabled = true ;
    console.log(template)
    //this.profils = await this.helper.getSelectList(environment.baseUrl+ environment.profil,['libelle']);
    if(currentSociete == null){
      this.isAdd = true ;
      this.societe = new pay_societe() ;
      this.titleModal = this.__('label_socite_new') ;
    }else {
      /*TEL*/
      this.currenCode = this.helper.getCodePaysByNum(currentSociete.telephone.substr(0,3));
      console.log('uren',this.currenCode)
      /*TEL*/
      this.isAdd = false ;
      this.titleModal = this.__('label_socite_edit') ;
      let res = await this.http.get<any>(environment.baseUrl+ environment.societe + '?id=' + currentSociete.id,valuesys.httpAuthOptions()).toPromise();

      this.societe = currentSociete ;

    }

    this.modalRef = this.modalService.show(template,{class:"modal-md",ignoreBackdropClick:true});
    elem.disabled = false ;
  }
  close(){
    this.societe = new pay_societe()
    this.modalRef.hide()
    this.societe_error = new pay_societe();
    this.picFile = null
    this.getFile = true
    this.picFileOrigine = null
    this.picFileExtension = null
  }
  delete(deletedSociete:pay_societe,elm){
    elm.disabled = true
    Swal.fire({
      title: this.__('msg.confirm'),
      text: this.__('msg.warning_delete'),
      icon: 'warning',
      showCancelButton: true,
      confirmButtonText: this.__('msg.confirmed_delete'),
      cancelButtonText: this.__('msg.cancel_delete')
    }).then(async (result) => {
      if (result.value) {
        let sup = await this.http.delete<any>(environment.baseUrl+environment.societe+"?id=" + deletedSociete.id,valuesys.httpAuthOptions()).toPromise();
        // this.rerender();
        if(sup.code == 204){
          this.societes= this.societes.filter(function (item,index) {
            return item.id != deletedSociete.id
          })
          Swal.fire(
            this.__('msg.deleted'),
            sup.msg,
            'success'
          )
        }else {
          this.toastr.error(sup.msg,this.__("msg.error"));
        }
        elm.disabled = false

        // For more information about handling dismissals please visit
        // https://sweetalert2.github.io/#handling-dismissals
      } else if (result.dismiss === Swal.DismissReason.cancel) {
        elm.disabled = false
        Swal.fire(
          this.__('msg.canceled'),
          this.__('msg.canceled_delete'),
          'error'
        )
      }
    })
  }

  async openModalDetails(template: TemplateRef<any>,currentSociete:pay_societe =null,elm) {
    elm.disabled = true
    // this.isAdd = false ;
    this.titleModal = this.__('label_details') ;
    // let res = await this.http.get<any>(environment.baseUrl+ environment.profil,valuesys.httpAuthOptions()).toPromise();
    // this.users = res.data
    let res1 = await this.http.get<any>(environment.baseUrl+ environment.societe + '?id=' + currentSociete.id,valuesys.httpAuthOptions()).toPromise();
    this.societe_details = res1.data ;
    console.log(this.societe_details)


    this.modalRef = this.modalService.show(template,{class:"modal-md",ignoreBackdropClick:true});
    elm.disabled = false
  }


  async toggleState(societe:pay_societe,state:number,btn:any){
    console.log(btn.hidden = true)
    $(btn).attr('disabled');
    btn.disabled = true;
    let toogle = await this.http.get<any>(environment.baseUrl+ environment.societeEdit+`?id=${societe.id}&etat=${state}`,valuesys.httpAuthOptions()).toPromise();
    if(toogle.code == 201){
      this.toastr.success(toogle.msg,this.__("msg.success"));
      societe.etat = toogle.data.etat

    }else {
      this.toastr.error(toogle.msg,this.__("msg.error"));
    }
    btn.disabled = false;
  }
  onCountryChange(m){
    //return false
    let lastDiall =  this.objetPhone .dialCode ;
    let newDiall = m.dialCode
    console.log(`LAST = ${lastDiall} ; NEW ${newDiall}`,m);
    this.element.value = this.element.value.replace(lastDiall+'',newDiall+'');
    this.societe.telephone = this.element.value
    this.objetPhone = m ;
  }
  telInputObject(m){
    this.objetPhone = m.s ;
  }

  controle(element){
    this.element = element ;
    element.value = element.value.replace(/[^0-9]/gi,'');
    if((element.value || "").substr(0,this.objetPhone.dialCode.length).indexOf(this.objetPhone.dialCode.toString()) == -1){
      if(element.value.length <=  this.objetPhone.dialCode.length  && (element.value || "").substr( this.objetPhone.dialCode.length ).length < 1)
      {
        element.value =  this.objetPhone.dialCode;
      }
      else
      {
        if((element.value || "").substr(0,this.objetPhone.dialCode.length ).indexOf( this.objetPhone.dialCode.toString()) !== -1){
          element.value =  element.value;
        }
        else {
          element.value = this.objetPhone.dialCode +  element.value;
        }
      }
    }
    this.societe.telephone = element.value;
  }


  handleUpload = async (event) => {
    let files = event.target.files;
    // this.picFileOrigine = files[0].name
    this.picFileExtension = files[0].name.split('.')[1]
    let reader = new FileReader();
    reader.readAsDataURL(files[0]);
    reader.onload = (_event) => {
      this.picFile = reader.result;
      if (this.picFile) {
        this.getFile = false
      }
    }
  }

}
