import {AfterViewInit, Component, OnDestroy, OnInit, TemplateRef, ViewChild} from '@angular/core';
import {ActivatedRoute, Router} from "@angular/router";
import {AuthServiceService} from "../../../service/auth-service.service";
import * as $ from  'jquery';
import Swal from 'sweetalert2' ;
import {Translatable} from "../../../../Translatable";
import {HttpClient} from "@angular/common/http";
import {ToastrService} from "ngx-toastr";
import {HelperService} from "../../../service/helper.service";
import {BsModalRef, BsModalService} from "ngx-bootstrap";
import {NgxUiLoaderService} from "ngx-ui-loader";
import {DataTablesResponse} from "../../../model/DataTablesResponse";
import {valuesys} from "../../../../../options";
import {profil, utilisateur} from "../../../model/db";
import {environment} from "../../../../../environments/environment";
import { Subject } from 'rxjs';

@Component({
  selector: 'app-profil-utilisateur',
  templateUrl: './profil-utilisateur.component.html',
  styleUrls: ['./profil-utilisateur.component.css']
})
export class ProfilUtilisateurComponent extends Translatable implements OnInit {


  title:string ;
  mdp: string= "";
  mdp_new: string= "";
  mdp_confirm_new: string= "";
  ok:boolean = false;
  error:boolean = false;

  public user :utilisateur = new utilisateur();
  constructor(
    private activatedRoute: ActivatedRoute,
    private auth : AuthServiceService,
    private http: HttpClient,
    private toastr: ToastrService,
    private helper: HelperService,
    private router: Router,
    private modalService: BsModalService,
    private ngxService: NgxUiLoaderService
  ) {
    super()
  }

  async ngOnInit() {
    this.title = this.__("Profile.user_profil");
    let currentUser :any  =  await this.auth.getLoginUser();
    let res1 = await this.http.get<any>(environment.baseUrl+ environment.userProfile+"?child=profil,paySociete",valuesys.httpAuthOptions()).toPromise();
    this.user = res1.data;
    this.user.societe = res1.data.paySociete;
  }
  controlConfirm(){
    if(this.mdp_new != ""){
      if(this.mdp_new === this.mdp_confirm_new){
        this.ok = true;
        this.error = false
      }else{
        this.ok = false;
        this.error = true
      }
    }
  }
  control(){
    if(this.mdp_new == ""){
      if(this.mdp_confirm_new !=""){
        this.ok = false;
        this.mdp_confirm_new = "";
        this.error = false
      }
    }
  }
  async updateMdp(){
    let resp  = await this.http.put<any>(environment.baseUrl+ environment.userRegenerePassword,{email:this.user.email,password_old:this.mdp,password:this.mdp_new},valuesys.httpAuthOptions()).toPromise();
    if(resp.code == 201){
      this.toastr.success(resp.msg, this.__("msg.success"));
      this.mdp = "";
      this.mdp_new = "";
      this.mdp_confirm_new = "";
      this.setDefault();
      this.router.navigate(['/logout'])
    }else {
      this.toastr.error(resp.msg, this.__("msg.error"));
      this.mdp = "";
      this.mdp_new = "";
      this.mdp_confirm_new = "";
      this.setDefault();
    }
  }
  setDefault(){
    this.ok = false;
    this.error = false;
  }
}
