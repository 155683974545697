import { Component, OnInit, TemplateRef, ViewChild} from '@angular/core';
import {ActivatedRoute} from "@angular/router";
import {AuthServiceService} from "../../../../service/auth-service.service";
import * as $ from  'jquery';
import Swal from 'sweetalert2' ;
import {Translatable} from "../../../../../Translatable";
import {HttpClient} from "@angular/common/http";
import {ToastrService} from "ngx-toastr";
import {HelperService} from "../../../../service/helper.service";
import {BsModalRef, BsModalService} from "ngx-bootstrap";
import {NgxUiLoaderService} from "ngx-ui-loader";
import {DataTablesResponse} from "../../../../model/DataTablesResponse";
import {valuesys} from "../../../../../../options";
import {profil, pay_societe, pay_employe} from "../../../../model/db";
import {environment} from "../../../../../../environments/environment";
import { Subject } from 'rxjs';
import {DataTableDirective} from "angular-datatables";
import { NgSelect2Component } from 'ng-select2';

@Component({
  selector: 'app-enrollement',
  templateUrl: './enrollement.component.html',
  styleUrls: ['./enrollement.component.css']
})
export class EnrollementComponent extends Translatable  implements  OnInit {
  //DATATABLE
  @ViewChild(DataTableDirective, {static: false})
  dtElement: DataTableDirective;

  title:string ;
  dtOptions: DataTables.Settings = {};
  modalRef: BsModalRef;
  titleModal:string ;
  isAdd : boolean = true ;
  isValid : boolean = false ;
  recap_employe :boolean =true ;
  search_emp :boolean =false ;
  objetPhone : any ;
  telephone : any ;
  recap : any ;
  element : any ;
  currenCode :string =environment.CURREN_CODE_COUNTRY;

  public employes :pay_employe[] = [] ;
  public employe:pay_employe = new pay_employe();
  public programmes: any;
  public programme: any;
  public employe_error : pay_employe ;
  constructor(private activatedRoute: ActivatedRoute,
              private auth : AuthServiceService,
              private http: HttpClient,
              private toastr: ToastrService,
              private helper: HelperService,
              private modalService: BsModalService,
              private ngxService: NgxUiLoaderService
  ) {
    super();
  }

  async ngOnInit() {
    this.initDatatable()
    this.recap = {}
  }
  initDatatable(){
    this.isAdd = true ;
    this.isValid = false ;
    this.title = this.__('employe.enroler_employe');
    this.dtOptions = this.helper.dataTableOption();
    this.dtOptions.columns = [
      {
        "data": "prenom"
      },
      {
        "data": "nom"
      },
      {
        "data": "telephone"
      },
      {
        "data": "email"
      },
      {
        "data": "adresse"
      },
      {
        "data": "nom_societe"
      }
    ]
    this.dtOptions.ajax = (dataTablesParameters: any, callback) => {
      this.http
        .get<DataTablesResponse>(
          environment.baseUrl+ environment.liste_employe+"?child=paySociete", valuesys.httpAuthOptionsProcess($.param(dataTablesParameters))
        ).subscribe(resp => {
        this.employes = resp.data;
        callback({
          recordsTotal: resp.recordsTotal,
          recordsFiltered: resp.recordsFiltered,
          data:[]
        });
      });
    }
  }

  async serachEmploye(){

    let res:any =  await this.http.get<any>(environment.baseUrl+ environment.oneEmploye+'?telephone='+this.telephone+"&type="+this.programme,valuesys.httpAuthOptions()).toPromise();
    console.log(res.data[0]);
    if (res.code == 200){
      this.recap = res.data[0];
      this.search_emp=true;
      this.recap_employe=false;
    }
    else {
      this.toastr.error(res.msg,this.__("msg.error")) ;
      this.recap_employe=true;
      this.search_emp=false;
      this.telephone = ""
    }
  }
  async addEmployeForm(){

    this.recap.cni= this.recap.piece;
    this.recap.age= this.recap.date_naissance;
    let res:any =  await this.http.post<any>(environment.baseUrl+ environment.creerEmployer,this.recap,valuesys.httpAuthOptions()).toPromise();
    if(res.code === 201){
      this.toastr.success(res.msg,this.__("msg.success")) ;
      this.employes.push( res.data);
      this.recap_employe = true;
      this.search_emp = false;
      this.telephone = ""

    }else if(res.code === 400){

      let msg = ''
      for (let item in res.data){
        msg += res.data[item][0] + "\n";
        this.employe_error[item] = res.data[item][0] ;
      }
      this.toastr.warning(msg,this.__("msg.warning")) ;
    }
    else {
      this.toastr.error(res.msg,this.__("msg.error")) ;
    }
  }
  return(){
    this.employe = new pay_employe()
    this.employe_error = new pay_employe();
    this.recap = {}
    this.recap_employe = true
    this.search_emp = false
    this.telephone = ""
  }
  delete(deletedEmploye:pay_employe,elm){
    elm.disabled = true
    Swal.fire({
      title: this.__('msg.confirm'),
      text: this.__('msg.warning_delete'),
      icon: 'warning',
      showCancelButton: true,
      confirmButtonText: this.__('msg.confirmed_delete'),
      cancelButtonText: this.__('msg.cancel_delete')
    }).then(async (result) => {
      if (result.value) {
        let sup = await this.http.delete<any>(environment.baseUrl+environment.supprimerEmployer+"?id=" + deletedEmploye.id,valuesys.httpAuthOptions()).toPromise();
        // this.rerender();
        if(sup.code == 204){
          this.employes= this.employes.filter(function (item,index) {
            return item.id != deletedEmploye.id
          })
          Swal.fire(
            this.__('msg.deleted'),
            sup.msg,
            'success'
          )
        }else {
          this.toastr.error(sup.msg,this.__("msg.error"));
        }
        elm.disabled = false

        // For more information about handling dismissals please visit
        // https://sweetalert2.github.io/#handling-dismissals
      } else if (result.dismiss === Swal.DismissReason.cancel) {
        elm.disabled = false
        Swal.fire(
          this.__('msg.canceled'),
          this.__('msg.canceled_delete'),
          'error'
        )
      }
    })
  }



  async toggleState(emp:pay_employe,state:number,btn:any){
    console.log(btn.hidden = true)
    $(btn).attr('disabled');
    btn.disabled = true;
    let toogle = await this.http.get<any>(environment.baseUrl+ environment.employeEditEtat+`?id=${emp.id}&etat=${state}`,valuesys.httpAuthOptions()).toPromise();
    if(toogle.code == 201){
      this.toastr.success(toogle.msg,this.__("msg.success"));
      emp.etat = toogle.data.etat

    }else {
      this.toastr.error(toogle.msg,this.__("msg.error"));
    }
    btn.disabled = false;
  }
  onCountryChange(m){
    //return false
    let lastDiall =  this.objetPhone .dialCode ;
    let newDiall = m.dialCode
    console.log(`LAST = ${lastDiall} ; NEW ${newDiall}`,m);
    this.element.value = this.element.value.replace(lastDiall+'',newDiall+'');
    this.employe.telephone = this.element.value
    this.objetPhone = m ;
  }
  telInputObject(m){
    this.objetPhone = m.s ;
  }

  controle(element){
    this.element = element ;
    element.value = element.value.replace(/[^0-9]/gi,'');
    if((element.value || "").substr(0,this.objetPhone.dialCode.length).indexOf(this.objetPhone.dialCode.toString()) == -1){
      if(element.value.length <=  this.objetPhone.dialCode.length  && (element.value || "").substr( this.objetPhone.dialCode.length ).length < 1)
      {
        element.value =  this.objetPhone.dialCode;
      }
      else
      {
        if((element.value || "").substr(0,this.objetPhone.dialCode.length ).indexOf( this.objetPhone.dialCode.toString()) !== -1){
          element.value =  element.value;
        }
        else {
          element.value = this.objetPhone.dialCode +  element.value;
        }
      }
    }
    this.employe.telephone = element.value;
  }
}
