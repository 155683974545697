export const environment = {
    production: false,
    baseur : "app/",
    security:true,
    allowAll: false,
  //baseUrl : "https://ms-gateway-benin.phco-preprod.com/server/",
  //baseUrl : "https://ms-gateway-phco.numherit-dev.com/server/",
    baseUrl : "https://ms-gateway-lbdi.phco-preprod.com/server/",
    listUser:'sunupaye/auth/utilisateur',
    login:'sunupaye/auth/token',
    userAuth:'sunupaye/auth/getUserConnected',
    ping:'sunupaye/auth/ping',
    refresh:'sunupaye/auth/refreshToken',
    authItemName:'__token_api_gate_way_phco__',
    userItemName:'__user_api_gate_way_phco__',
    user: 'sunupaye/users/user',
    userProfile: 'sunupaye/users/monprofil',
    userRegenerePassword: 'sunupaye/users/edit-pwd-user',
    typebureau :'administration/typebureau/typebureau',
    // bureau :'sunupaye/pay_societe/pay_societe',
    pays :'administration/pays/pays',
    region :'administration/region/region',
    bureauEdit :'administration/bureau/edit-etat',
    bureauCredit :'monetique/bureau/crediter-agence',
    profil :'sunupaye/profil/profil',
    typeProfil :'administration/type_profil/type_profil',
    affectation_droit :'sunupaye/profil/affectation',
    modules : 'sunupaye/sous_module/sous_module',
    service : 'administration/service/service',
    tarif : 'administration/tarif_frais/tarif_frais',
    userEdit:'sunupaye/users/edit-etat',
    moduleEdit:'administration/module/edit-etat',
    sModules:'administration/sousmodule/sousmodule',
    sModuleEdit:'administration/sousmodule/edit-etat',
    typebureauEdit:'administration/typebureau/edit-etat',
    paysEdit :'administration/pays/edit-etat',
    regionEdit :'administration/region/edit-etat',
    nationalite :'administration/nationalite/nationalite',
    profession :'administration/profession/profession',
    wallet :'monetique/wallet/wallet',
    profilEdit:'sunupaye/profil/edit-etat',
    serviceEdit:'administration/service/edit-etat',
    typeProfilEdit :'administration/typeprofil/edit-etat',
    typepiece: 'administration/typepiece/typepiece',
    walletEdit: 'monetique/wallet/edit-etat',
    bureauCreditAgence :'monetique/bureau/transfert-agence-agence',
    virement: 'administration/virement/virement',
    validerVirement: 'monetique/compte/valider-virement',
    suiviCompte: 'reporting/releve_compte_bureau/releve_compte_bureau',
    enrolement: 'monetique/carte/enroler-carte',
    carte: 'sunupaye/carte/carte',
    carteEdit: 'sunupaye/carte/edit-etat',
    soldeCarte: 'sunupaye/carte/consulter-solde',
    activerCarte: 'monetique/carte/activer-carte',
    bloquerCarte: 'monetique/carte/desactiver-carte',
    transaction:'reporting/transaction/transaction',
    recharge_espece:'api/gtp/recharger-carte',
    transfertHistorique: 'reporting/transfert/transfert',
    typeCarte: "administration/typecarte/typecarte",
    stock: "monetique/lotcarte/lotcarte",
    walletRechargeEspeceGenerationCode: 'monetique/wallet/generer-code',
    walletRechargeEspeceValidationCode: 'monetique/wallet/valider-code',
    retrait_espece_carte:'api/gtp/debiter-carte',
    genererCode:'monetique/secure_code/generer-code',
    validerCode:'monetique/secure_code/valider-code',
    transfertEnvoi: 'monetique/transfert/envoi',
    CODE_RECHARGE_ESPECE: 5,
    CODE_TRANSFERT: 4,
    CODE_RETRAIT: 3,
    CODE_PAIEMENT_SALAIRE: 27,
    calculFrais: 'administration/service/calcul-frais',
    historiqueTransactionWallet: 'reporting/transaction/transaction',
    histo_transaction_carte: 'reporting/transaction/transaction',
    detailsTransfert: 'monetique/transfert/detailTransfert',
    ID_TYPE_BUREAU_AGENCE:3,
    ID_TYPE_BUREAU_DISTRIBUTEUR:1,
    ENVOI_VERS_DIRECTION_GENERALE:0,
    RECEPTION_DIRECTION_GENERALE:1,
    DISTRIBUTION_GENERALE :2,
    RECEPTION_VERS_AGENCE:3,
    RETOUR_VERS_DIRECTION_GENERALE:4,
    VENTE:5,
    walletRetraitEspeceGenerationCode: 'monetique/wallet/generer-code-retrait',
    walletRetraitEspeceValidationCode: 'monetique/wallet/valider-code-retrait',
    transfertPaiement: 'monetique/transfert/paiement',
    detailsTransfertAnnulation: 'monetique/transfert/recap-sender',
    TransfertAnnulation: 'monetique/transfert/edit-etat',
    genererCodeRechargeCarte:'monetique/Carte/generer-code',
    validationCodeRechargeCarte:'monetique/carte/valider-code',
    genererCodeRetraitCarte:'monetique/Carte/generer-code-retrait',
    validationCodeRetraitCarte:'monetique/carte/valider-code-retrait',
    envoiDirection:'monetique/stock/envoi-caveau',
    receptionDirection:'monetique/stock/reception-caveau',
    distributionDirection:'monetique/stock/envoi-vers-agence',
    receptionAgence:'monetique/stock/reception-agence',
    retourLotAgence:'monetique/stock/retour-lot-agence',
    histo_stockCarte:'monetique/stock/stock',
    interditBancaire:'administration/interdit_bancaire/interdit_bancaire',
    interdit_parametrage:'administration/interdit_bancaire/parametre-interdit',
    liste_parametrage:'administration/interdit_bancaire/parametre-interdit',
    liste_interdit:'administration/interdit/interdits',
    CODE_RECHARGE_JULA: 1,
    CODE_CARTE_PARTICULIER: 7,
    CODE_CARTE_COMMERCANT: 8,
    CODE_transfert_carte_carte_par_mois:"" ,
    marchand:'administration/marchand/marchand',
    regenereCleSecrete:'administration/marchand/edit-key',
    marchandEdit: 'administration/marchand/edit-etat',
    appelFondEditStatut: 'administration/appels_fond/edit-etat',
    appelFonds: 'administration/appels_fond/appel-fond',
    validationCodeRechargeAgence: 'monetique/bureau/validation-code-recharge',
    CURREN_CODE_COUNTRY : 'lr',

    // PLATEFORME SUNU PAY

    societe :'administration/pay_societe/pay_societe',
    societeEdit :'administration/pay_societe/edit-etat',
    notification :'sunupaye/pay_mail_virement/pay_mail_virement',
    societeListe :'administration/pay_societe/pay_societe',
    suiviCompteVirement :'sunupaye/pay_releve_compte_societe/pay_releve_compte_societe',
    ajoutVirement :'sunupaye/virement/AjoutVirement',
    histoVirement :'sunupaye/pay_virement/pay_virement',
    validationVirement: 'sunupaye/virement/ValidationViremennt',
    commissionVirement :'sunupaye/virement/CalculComVirement',
    detailWalletEmploye: 'sunupaye/wallet/wallet',
    payHistoriqueTransaction: 'sunupaye/wallet/historique-transaction',
    payWalletGenererCode: 'sunupaye/wallet/generer-code',
    payWalletActiverCode: 'sunupaye/wallet/recharger-wallet',
    payWalletActiverCodeMultiple: 'sunupaye/wallet/rechargement-wallet',
    histoTransactionCarte:'sunupaye/wallet/historique-transaction',
    genererCodeCarte:'sunupaye/carte/generer-code',
    validerCodeCarte:'sunupaye/carte/valider-code',
    rechargementApresValidation:'sunupaye/carte/recharger-wallet',
    rechargementCarteParLot:'sunupaye/carte/recharger-lot',
    rechargementWalletParLot:'sunupaye/wallet/recharger-lot',
    historiqueTransactionCarte: 'sunupaye/wallet/historique-transaction',
    validerCodeMultiple: 'sunupaye/carte/crediter-carte',
    log: 'sunupaye/users/user-log',
    enrollerEmployeLot: 'sunupaye/pay_employe/enroler-lot-employer',
    enrollerEmployeDetails: 'sunupaye/pay_employe/enroler-employer',
    liste_employe: 'sunupaye/pay_employe/lister-employer',
    employeEditEtat: 'sunupaye/pay_employe/edit-etat',
    updateEmploye: 'sunupaye/pay_employe/insert-employer',
    oneEmploye: 'sunupaye/pay_employe/enroler-employer',
    creerEmployer: 'sunupaye/pay_employe/insert-employer',
    supprimerEmployer: 'sunupaye/pay_employe/supprimer_employer',
    soldeSociete: 'administration/pay_societe/solde_societe',
    userRegenerePasswordListUser: 'sunupaye/users/edit-pwd',
    TIMMER_GUARD:600,
    uploadFileEnrolement: "sunupaye/pay_employe/upload-employe",
    uploadFileCreditCarte: "sunupaye/carte/upload-credit-carte",
    uploadFileCreditWallet: "sunupaye/wallet/upload-credit-wallet",
    supprimerVirement: 'sunupaye/pay_virement/delete-virement',
    devise: "administration/settings/devise"
};
