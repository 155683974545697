import {AfterViewInit, Component, OnDestroy, OnInit, ViewChild} from '@angular/core';
import {ActivatedRoute} from "@angular/router";
import {AuthServiceService} from "../../../service/auth-service.service";
import {HttpClient} from "@angular/common/http";
import {ToastrService} from "ngx-toastr";
import {HelperService} from "../../../service/helper.service";
import {NgxUiLoaderService} from "ngx-ui-loader";
import {DataTablesResponse} from "../../../model/DataTablesResponse";
import {Translatable} from "../../../../Translatable";
import { pay_virement,pay_releve_compte_societe,pay_societe} from "../../../model/db";
import {environment} from "../../../../../environments/environment";
import {valuesys} from "../../../../../options";
import { DataTableDirective } from 'angular-datatables';
import * as $ from 'jquery'
import { Subject } from 'rxjs';
import {BsModalRef, BsModalService} from "ngx-bootstrap";

@Component({
  selector: 'app-virement-suivi',
  templateUrl: './virement-suivi.component.html',
  styleUrls: ['./virement-suivi.component.css']
})
export class VirementSuiviComponent extends Translatable  implements AfterViewInit, OnDestroy, OnInit {
  ngAfterViewInit(): void {
    this.dtTrigger.next();
  }


  ngOnDestroy(): void {
    this.dtTrigger.unsubscribe();
  }
  rerender(): void {
    this.dtElement.dtInstance.then((dtInstance: DataTables.Api) => {
      // Destroy the table first
      dtInstance.destroy();
      // Call the dtTrigger to rerender again
      this.dtTrigger.next();
    });
  }
  /*ddd*/
  @ViewChild(DataTableDirective, {static: false})
  dtElement: DataTableDirective;

  // @ts-ignore
  dtTrigger: Subject = new Subject();
  /*ddd*/
  title:string = this.__('label_releve_compte');
  datatableElement: DataTableDirective;
  suiviComptes : pay_releve_compte_societe []=[];

  dtOptions: DataTables.Settings = {};
  modalRef: BsModalRef;
  titleModal:string ;
  private now = (new Date()).toISOString().replace('T',' ').substr(0,10);
  private endTime = " 23:59:59" ;
  date_debut : string = this.now;
  date_fin : string= this.now;
  public pay_societe_id: any;
  public listeSociete:any [] = [new pay_societe()];



  constructor(private activatedRoute: ActivatedRoute,
              private auth: AuthServiceService,
              private http: HttpClient,
              private toastr: ToastrService,
              private helper: HelperService,
              private modalService: BsModalService,
              private ngxService: NgxUiLoaderService
  ) {

    super();
  }

  async ngOnInit() {
    this.iniPeriode();
    this.initDatatable();
  }

  initDatatable(){

    this.dtOptions = this.helper.dataTableOption();
    this.dtOptions.columns = [
      {data:"date"},
      {data:"num_transac"},
      {data:"solde_avant"},
        {data:"montant"},
      {data:"solde_apres"},
      {data:"commentaire"}
    ]  ;
    this.dtOptions.ajax = (dataTablesParameters: any, callback) => {
      this.http
        .get<DataTablesResponse>(
          environment.baseUrl +  environment.suiviCompteVirement+`?where=date_transaction|se|${this.date_debut},date_transaction|ie|${this.date_fin + this.endTime}`, valuesys.httpAuthOptionsProcess($.param(dataTablesParameters))
        ).subscribe(resp => {
        this.suiviComptes = resp.data;
        callback({
          recordsTotal: resp.recordsTotal,
          recordsFiltered: resp.recordsFiltered,
          data:[]
        });
      });
    }
  }

  searchDate(){

      let periode = $('#periode').val();
      if(periode != ''){
          let tab = periode.toString().split(' - ');
          this.date_debut = this.formatDate(tab[0]);
          this.date_fin = this.formatDate(tab[1]);
      }
    this.rerender()
  }
    formatDate(date) {
        var d = date.split('/');
        return [d[2], d[1], d[0]].join('-');
    }

    getDate(date){
        if(date != '' && date != null) {
            const tab = date.split(' ');

            const d = tab[0].split('-');
            if (tab.length == 2) {
                const dt = d[2] + "/" + d[1] + "/" + d[0] + " " + this.__("a") + " " + tab[1];
                return dt;
            }
            else {
                const dt = d[2] + "/" + d[1] + "/" + d[0];
                return dt;
            }
        }
        else return 'Pas de date';
    }

    iniPeriode(){
        let dayNow = new Date()
        let now = (dayNow).toISOString().substr(0,10)
        let now_ = (dayNow).toISOString().substr(0,10).split('-').reverse().join("/")
        this.date_debut = now;
        this.date_fin = now;
        $('#periode').val(now_ + " - " + now_);
    }

}
