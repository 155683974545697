import { Injectable } from '@angular/core';
import {HttpClient} from "@angular/common/http";
import {environment} from "../../../environments/environment";
import {valuesys} from "../../../options";
import {Router} from "@angular/router";
import Login from "../model/Login";
import {profilage_module} from "../model/db";

@Injectable({
  providedIn: 'root'
})
export class AuthServiceService {

  constructor(private httpClient: HttpClient,private  router: Router) { }

 public async setLoginUser(user:any){
    try {
      if(user){
        localStorage.setItem(environment.userItemName, JSON.stringify(user));
        return  true
      }
      return false ;
    } catch (e) {
      return  false ;
    }
  }

  public async getUser(){

  }
  /*
  * Permet de retourner l'utilisateur actuellement connecté
  * le reload a true permet de faire une appel a api gatewate pour rafraissir
  * le reload a false permet de prendre l'utilisateur stocker en local
  * Si le reload est a false et que l'utilisateur actuel est null on rafraissit aussi
  * */
 public async  getLoginUser(reload:boolean = false){
    try {
      let user ={};
      if(environment.security === false){
        return  user ;
      }
      if(reload || !localStorage.getItem(environment.userItemName)){
          let lang = localStorage.getItem('lang') || window.navigator.language;
        let lang_tab = lang.split('-');
          //let lang = localStorage.getItem('lang') || window.navigator.language;
        let res = await this.httpClient.get<any>(environment.baseUrl + environment.userAuth+"?lang="+lang_tab[0], valuesys.httpAuthOptions()).toPromise() ;
        if(res.code === 200){
          user = res.data ;
          this.setLoginUser(user) ;
        }else {
          await  this.router.navigate(["/logout"])
        }
      }
     user =  JSON.parse(localStorage.getItem(environment.userItemName) || "{}");
      return user ;
    } catch (e) {
      return  await  this.router.navigate(["/logout"]) ;
    }
  }

  public async isConnected(reload = false){
    try {
      if(environment.security === false){
        return  200 ;
      }
      if(reload === false){
        if(localStorage.getItem(environment.authItemName))
        {
          return  200 ;
        }
        return 300 ;
      }
      let res = await this.httpClient.get<any>(environment.baseUrl + environment.ping, valuesys.httpAuthOptions()).toPromise();
      if (res.code === 200) {
        return 200;
      }
      return 300;
    } catch (e) {
      console.log(e);
      return 500;
    }
  }

  public setToken(token:string){
    localStorage.setItem(environment.authItemName, token);
  }
  public async getModuleById(id = null) {
    let user: any = await this.getLoginUser(false);
    return user.law.droit[id].module.libelle;
  }
  public async getSousModuleById(idMod = null,idSm =null) {
    let user: any = await this.getLoginUser(false);
    //console.log("AD",user.law) ;
    if(user.law.droit[idMod]){
      return user.law.droit[idMod].sous_module[idSm].libelle
    }
    //console.log("AD-"+idMod,user.law.droit[idSm].sous_module[idSm].libelle) ;
   // return user.law.droit[idSm].sous_module[idSm].libelle

  }
    public async getDroit(){
        let user: any = await this.getLoginUser(false);
        return user.law.droit
    }
  public async getMenu(basemodule : string, code = null){
      let user: any = await this.getLoginUser(false);

      let module: any = {libelle:'', sous_modules:[]} ;
      let sous_modules: any = [];

      if(code) {
        let mod_ = user.law.find((item:any)=>item.code == code);
        if(!mod_){
          //Si l'code du module n'existe pas on redirige vers la page d'acccueil app
          this.router.navigate(['/app']);
          return false ;
        }
          let sous_module = mod_.sous_modules;
          module.libelle = mod_.libelle;
          for (let j = 0 ; j <   sous_module.length;j++) {
              sous_modules.push({
                  icone : sous_module[j].icon,
                  sous_module: sous_module[j].libelle,
                  url: basemodule + code + "/" + sous_module[j].code
              })
          }
          module.sous_modules = sous_modules;
          return module;
      }
      module = [] ;
      for (let i=0 ;  i <  user.law.length ; i++ ) {
          module.push({

              icone : user.law[i].icon,
              module: user.law[i].libelle ,
              url: basemodule + user.law[i].code
          })
      }
      return module;
  }

  async loginUser(login:Login){

    try {
      let response: any = await this.httpClient.post<any>(environment.baseUrl + environment.login, login, valuesys.httpOptions).toPromise();
      if (response.code === 200) {
        return  response ;
      } else {
        return response ;
      }
    } catch (e) {
      console.log(e)
      return  false ;
    }
  }
}
