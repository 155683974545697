import {AfterViewInit, Component, OnDestroy, OnInit, TemplateRef, ViewChild} from '@angular/core';
import {ActivatedRoute} from "@angular/router";
import {AuthServiceService} from "../../../service/auth-service.service";
import {HttpClient} from "@angular/common/http";
import {ToastrService} from "ngx-toastr";
import {HelperService} from "../../../service/helper.service";
import {NgxUiLoaderService} from "ngx-ui-loader";
import {Translatable} from "../../../../Translatable";
import {devise, pay_employe, pay_releve_compte_societe, pay_societe, pay_virement, virement} from "../../../model/db";
import {environment} from "../../../../../environments/environment";
import {valuesys} from "../../../../../options";
import {DataTablesResponse} from "../../../model/DataTablesResponse";
import Swal from "sweetalert2";
import {BsModalRef, BsModalService} from "ngx-bootstrap";
import * as $ from "jquery";
import {Subject} from "rxjs/index";
import {DataTableDirective} from "angular-datatables";

@Component({
  selector: 'app-virement',
  templateUrl: './virement.component.html',
  styleUrls: ['./virement.component.css']
})
export class VirementComponent extends Translatable  implements AfterViewInit, OnDestroy, OnInit {
    ngAfterViewInit(): void {
        this.dtTrigger.next();
    }


    ngOnDestroy(): void {
        this.dtTrigger.unsubscribe();
    }
    rerender(): void {
        this.dtElement.dtInstance.then((dtInstance: DataTables.Api) => {
            // Destroy the table first
            dtInstance.destroy();
            // Call the dtTrigger to rerender again
            this.dtTrigger.next();
        });
    }
    /*ddd*/
    @ViewChild(DataTableDirective, {static: false})
    dtElement: DataTableDirective;

    // @ts-ignore
    dtTrigger: Subject = new Subject();
    datatableElement: DataTableDirective;
  title:string = this.__('virement.virement');
  virements : pay_virement []=[];
  virement: pay_virement = new pay_virement() ;
  dtOptions: DataTables.Settings = {};
  modalRef: BsModalRef;
  titleModal:string ;
  isValid : boolean = false;
  public recap: any;

   nouveau_virement :boolean = false;
   recap_virement :boolean = true;
  public montant: any;
  public pay_societe_id: any;
  public typevirement: any;
    private now = (new Date()).toISOString().replace('T',' ').substr(0,10);
    private endTime = " 23:59:59" ;
    date_debut : string = this.now;
    date_fin : string= this.now;
  virementType: any [] = [
    {
      text:this.__('virement.deduit_frais'),
      id: "1"
    },
    {
      text:this.__('virement.ajout_frais'),
      id: "0"
    }
  ];
  public listeSociete:any [] = [new pay_societe()];


  public devises: devise[] = [];
  code_devise: string;
  constructor(private activatedRoute: ActivatedRoute,
              private auth: AuthServiceService,
              private http: HttpClient,
              private toastr: ToastrService,
              private helper: HelperService,
              private modalService: BsModalService,
              private ngxService: NgxUiLoaderService
  ) {

    super();
  }

  async ngOnInit() {
    this.iniPeriode();
    this.initDatatable();
    this.recap = {}
  }

  initDatatable(){

    this.title = this.__("virement.history");
    this.dtOptions = this.helper.dataTableOption();
    this.dtOptions.columns = [
      {data:"date_crea"},
      {data:"montant"},
      {data:"montant_net"},
      {data:"montant_com"},
      {data:"montant_ttc"},
      {data:"statut"}
    ]  ;
    this.dtOptions.ajax = (dataTablesParameters: any, callback) => {
      this.http
        .get<DataTablesResponse>(
          environment.baseUrl+ environment.histoVirement+`?where=date_virement|se|${this.date_debut},date_virement|ie|${this.date_fin + this.endTime}`, valuesys.httpAuthOptionsProcess($.param(dataTablesParameters))
        ).subscribe(resp => {
        this.virements = resp.data;
        callback({
          recordsTotal: resp.recordsTotal,
          recordsFiltered: resp.recordsFiltered,
          data:[]
        });
      });
    }
  }

  async openModal(template: TemplateRef<any>,currenVirement:pay_virement =null) {
      this.devises = await this.helper.getSelectList(environment.baseUrl+ environment.devise,['nom', 'code'], 'code');
    this.titleModal = this.__('virement.new') ;
    this.modalRef = this.modalService.show(template,{ignoreBackdropClick:true});
  }
  close(){
    this.virement = new pay_virement();
    this.modalRef.hide()
    this.nouveau_virement=true;
  }
  controlMontant(element){
    element.value = (element.value || '').replace(/[^0-9]/,'') || '';
  }


  async validationVirement(id,statut,item:pay_virement){
    Swal.fire({
      title: this.__('msg.confirm'),
      text: this.__('msg.alerte_validation'),
      icon: 'warning',
      showCancelButton: true,
      confirmButtonText: this.__('msg.confirmed_validation'),
      cancelButtonText: this.__('msg.cancel_delete')
    }).then(async (result) => {
      if (result.value) {
        let toogle =  await this.http.put<any>(environment.baseUrl+ environment.validationVirement,{idVirement:id},valuesys.httpAuthOptions()).toPromise();        // this.rerender();
        if(toogle.code == 201){
          item.statut=1;
          this.virements= this.virements.filter(function (item,index) {
            return statut= 1
          });
          Swal.fire(
            this.__('msg.valided'),
            toogle.msg,
            'success'
          )
        }else {
          this.toastr.error(toogle.msg,this.__("msg.error"));
        }

        // For more information about handling dismissals please visit
        // https://sweetalert2.github.io/#handling-dismissals
      } else if (result.dismiss === Swal.DismissReason.cancel) {
        Swal.fire(
          this.__('msg.canceled'),
          this.__('msg.virement_annuler'),
          'error'
        )
      }
    })


  }
  async partieVirement1(){
    console.log(this.virement);
    this.nouveau_virement=true;

    let res2:any = await this.http.get<any>(environment.baseUrl+ environment.commissionVirement+'?montant='+this.montant+'&avecsansfrais='+this.typevirement,valuesys.httpAuthOptions()).toPromise();
    if (res2.code == 200){
      this.recap = res2.data;
      this.recap_virement=false;
    }
    else {
      this.toastr.error(res2.msg,this.__("msg.error")) ;
      this.recap_virement=true;
      this.nouveau_virement=true;
    }
  }

  async  addFmr(){

    console.log('CodeDEVISE',this.code_devise)
    let res:any =  await this.http.put<any>(environment.baseUrl+ environment.ajoutVirement,{montant:this.montant,id:this.pay_societe_id,avecsansfrais:this.typevirement,code_devise:this.code_devise},valuesys.httpAuthOptions()).toPromise();
    if(res.code === 201){
      this.toastr.success(res.msg,this.__("msg.success")) ;
      this.virements.push(res.data);
      this.close()
    }else {
      this.toastr.error(res.msg,this.__("msg.error")) ;
    }
  }
    searchDate(){
        //this.init_ = false;
        let periode = $('#periode').val();
        if(periode != ''){
            let tab = periode.toString().split(' - ');
            this.date_debut = this.formatDate(tab[0]);
            this.date_fin = this.formatDate(tab[1]);
        }
        this.rerender()
    }

    formatDate(date) {
        var d = date.split('/');
        return [d[2], d[1], d[0]].join('-');
    }

    getDate(date){
        if(date != '' && date != null) {
            const tab = date.split(' ');

            const d = tab[0].split('-');
            if (tab.length == 2) {
                const dt = d[2] + "/" + d[1] + "/" + d[0] + " " + this.__("a") + " " + tab[1];
                return dt;
            }
            else {
                const dt = d[2] + "/" + d[1] + "/" + d[0];
                return dt;
            }
        }
        else return this.__('msg.no_date');
    }

    iniPeriode(){
        let dayNow = new Date()
        let now = (dayNow).toISOString().substr(0,10)

        let days = 30;
        let newDate = new Date(Date.now() - days * 24*60*60*1000);
        let now2 = (newDate).toISOString().substr(0,10)
        let now_ = (dayNow).toISOString().substr(0,10).split('-').reverse().join("/")
        let now2_ = (newDate).toISOString().substr(0,10).split('-').reverse().join("/")
        this.date_debut = now2;
        this.date_fin = now;
        $('#periode').val(now2_ + " - " + now_);
    }


    delete(deletedVirement:pay_virement,elm){
        elm.disabled = true
        Swal.fire({
            title: this.__('msg.confirm'),
            text: this.__('msg.warning_delete'),
            icon: 'warning',
            showCancelButton: true,
            confirmButtonText: this.__('msg.confirmed_delete'),
            cancelButtonText: this.__('msg.cancel_delete')
        }).then(async (result) => {
            if (result.value) {
                let sup = await this.http.delete<any>(environment.baseUrl+environment.supprimerVirement+"?id=" + deletedVirement.id,valuesys.httpAuthOptions()).toPromise();
                // this.rerender();
                if(sup.code == 204){
                    this.virements= this.virements.filter(function (item,index) {
                        return item.id != deletedVirement.id
                    })
                    Swal.fire(
                        this.__('msg.deleted'),
                        sup.msg,
                        'success'
                    )
                }else {
                    this.toastr.error(sup.msg,this.__("msg.error"));
                }
                elm.disabled = false

                // For more information about handling dismissals please visit
                // https://sweetalert2.github.io/#handling-dismissals
            } else if (result.dismiss === Swal.DismissReason.cancel) {
                elm.disabled = false
                Swal.fire(
                    this.__('msg.canceled'),
                    this.__('msg.canceled_delete'),
                    'error'
                )
            }
        })
    }


}
