import { Component, OnInit, TemplateRef } from '@angular/core';
import { Translatable } from "../../../../../Translatable";
import {DataTableDirective} from "angular-datatables";
import {HelperService} from "../../../../service/helper.service";
import {HttpClient} from "@angular/common/http";
import {valuesys} from "../../../../../../options";
import {environment} from "../../../../../../environments/environment";
import {tarif_frais, pay_employe} from "../../../../model/db";
declare const require: any;
const jsPDF = require('jspdf');
require('jspdf-autotable');
import html2canvas from 'html2canvas';
import {ToastrService} from "ngx-toastr";
import {BsModalRef, BsModalService} from "ngx-bootstrap";

@Component({
  selector: 'app-enrollement-lot',
  templateUrl: './enrollement-lot.component.html',
  styleUrls: ['./enrollement-lot.component.css']
})
export class EnrollementLotComponent extends Translatable implements OnInit {

  modalRef: BsModalRef;
  fileText: any[] = [];
  dtOptions: DataTables.Settings = {};
  dtOptions_Credit: DataTables.Settings = {};
  nbrTotalLigne:number = 0;
  nbrTotalLigneC:number = 0;
  nbrTotalLigneNC:number = 0;
  nbrTotalMontant:number = 0;
  public beneficiaire: pay_employe[];
  public beneficiaires:pay_employe[]=[];
  creditState:number = 0;
  getError:boolean = false;
  public titleModal: any;
  partie1: boolean = false;
  partie2: boolean = true;
  montant: any;
  code: any;
  idCodeValidation: any;
  frais_calculate: any ={
    "ht": 0,
    "tva":0,
    "ttc": 0,
    "res": 3
  }
  frais: tarif_frais;
    public programme: string;
  public recharge: any = {};
  validated: boolean = false;
  uploaded: boolean = false;
  public idCode_validation : string;

  constructor(
    private http: HttpClient,
    private helper: HelperService,
    private toastr: ToastrService,
    private modalService: BsModalService
  ) {
    super();
    this.programme = ''
  }

  async ngOnInit() {
    //let resp = await this.http.get<any>(environment.baseUrl+ environment.wallet ,valuesys.httpAuthOptions()).toPromise();
    //this.beneficiaires = resp.data[0]
    //console.log(this.beneficiaires)
  }

  readUploadedFileAsText = (inputFile:File) => {
    let temporaryFileReader = new FileReader();
    return new Promise((resolve, reject) => {
      temporaryFileReader.onerror = () => {
        temporaryFileReader.abort();
        reject(new DOMException(this.__("bureau.echec_import_fichier")));
      };

      temporaryFileReader.onload = () => {
        resolve(temporaryFileReader.result);
      };
      temporaryFileReader.readAsText(inputFile);
    });
  };

    async fileChange(event) {
      if(this.programme != '' && this.programme != 'undefined') {
          let fileList: FileList = event.target.files;
          if (fileList.length > 0) {
              let file: File = fileList[0];
              let formData: FormData = new FormData();
              formData.append('file', file, file.name);
              formData.append('type', this.programme);
              /*let headers = new Headers();
              /** In Angular 5, including the header Content-Type can invalidate your request *!/
              headers.append('Content-Type', 'multipart/form-data');
              headers.append('Accept', 'application/json');
              let options = new RequestOptions({ headers: headers });*/
              let res:any = await this.http.post<any>(environment.baseUrl+ environment.uploadFileEnrolement, formData, valuesys.httpUploadFileOptions()).toPromise();
              //console.log(res.data.Resultat);
              if (res.code == 200 ) {
                  this.uploaded = true;
                  this.beneficiaires = res.data;
                  let fileContents = await this.readUploadedFileAsText(file)
                  //console.log(fileContents.toString().split('\n'))
                  let fileTextString = fileContents.toString().split('\n').map((item)=>{
                      let eltFound:pay_employe = this.beneficiaires.find(element => item.split(";")[0] == element.telephone)
                      //console.log(eltFound)
                      if(!eltFound){
                          eltFound = new pay_employe()
                      }

                      let ob = {telephone:item.split(";")[0] ,nom:eltFound.nom + " " + eltFound.prenom, age:eltFound.age, cni:eltFound.cni, adresse:eltFound.adresse, email:eltFound.email, matricule:eltFound.matricule}
                      return ob
                      //console.log(eltFound)
                  }).filter(_=>_.telephone != '');

                  this.fileText = fileTextString
                  //console.log(this.fileText)
                  this.dtOptions_Credit = this.helper.dataTableOptionOffline();

                  //suppression des doublons
                  this.fileText = this.fileText.filter((set => f => !set.has(f.telephone) && set.add(f.telephone))(new Set));

              } else {
                  this.toastr.error(res.msg, this.__("msg.error"));
              }
          }
          else{
              this.toastr.error(this.__("msg.choice_file"), this.__("msg.error"));
          }
      }
      else{
          this.toastr.error(this.__("msg.choice_programme"), this.__("msg.error"));
      }
    }

  hasFinish: boolean = false;

  /*async handleUpload(event) {
    this.uploaded = true;
    let file = event.target.files[0];
    try {
      let fileContents = await this.readUploadedFileAsText(file)
      console.log(fileContents.toString().split('\n'))
      let fileTextString = fileContents.toString().split('\n').map((item)=>{
        let eltFound:pay_employe = this.beneficiaires.find(element => item.split(";")[0] == element.telephone)
        console.log(eltFound)
        if(!eltFound){
          eltFound = new pay_employe()
        }

        let ob = {telephone:item.split(";")[0] ,nom:eltFound.nom + " " + eltFound.prenom, date_naissance:eltFound.date_naissance, piece:eltFound.piece, adresse:eltFound.adresse, email:eltFound.email}
        return ob
        console.log(eltFound)
      }).filter(_=>_.telephone != '');

      this.fileText = fileTextString
      this.dtOptions_Credit = this.helper.dataTableOptionOffline();

      //suppression des doublons
      this.fileText = this.fileText.filter((set => f => !set.has(f.telephone) && set.add(f.telephone))(new Set));

    } catch (e) {
      console.warn(e.message)
    }
  }*/

  checkWallet(tel:string, textItem) {
    this.fileText = this.fileText.filter((set => f => !set.has(f.telephone) && set.add(f.telephone))(new Set));
    let res : pay_employe = this.beneficiaires.find((item:pay_employe) => item.telephone == tel);
    if(res){
      textItem.nom = res.prenom + " " + res.nom;
      textItem.email = res.email;
      textItem.cni = res.cni;
      textItem.age = res.age;
      textItem.adresse = res.adresse;
      textItem.matricule = res.matricule;
      return true ;
    } else {
      textItem.nom = '';
      textItem.email = '';
      textItem.cni = '';
      textItem.age = '';
      textItem.adresse = '';
    }
    return false
  }

  deleteRow(text){
    this.fileText = this.fileText.filter((item)=>{
      return text != item
    })
    this.nbrTotalLigne = this.fileText.length;
  }

  hasError(){
    let error=false
    if(this.fileText.length == 0) return true;
    this.fileText.map((item)=>{

      if( this.beneficiaires.filter((b)=> b.telephone != item.telephone).length == this.beneficiaires.length){
        error = true
      }
    });

    return error;
  }

  /*close() {
    this.modalRef.hide();
  }*/

  async procedToExport(){
    let data = await  document.getElementById('contentToConvert');
    html2canvas(data).then(canvas => {

      // Few necessary setting options
      let imgWidth = 200;
      let pageHeight = 400;
      let imgHeight = canvas.height * imgWidth / canvas.width;
      let heightLeft = imgHeight;

      const contentDataURL = canvas.toDataURL('image/png')
      let pdf = new jsPDF('p', 'mm', 'a4'); // A4 size page of PDF
      let x = 0;
      let y = 0;
      let today = new Date().toLocaleDateString()
      pdf.addImage(contentDataURL, 'PNG', x, y, imgWidth, imgHeight)
      pdf.save(`Recap-credit-plusieurs-agences-${today}.pdf`); // Generated PDF
      this.toastr.success(this.__("exportPDF.export_ok"),this.__("msg.success"));
    })
  }

  async genererCode() {
    let res:any = await this.http.get<any>(environment.baseUrl+ environment.enrollerEmployeDetails,valuesys.httpAuthOptions()).toPromise();
    console.log(res.data.Resultat);
    if (res.code == 201 ) {
    } else {
      this.toastr.error(res.msg, this.__("msg.error"));
    }
  }

  async validatePartie2 () {
      this.nbrTotalLigneNC = 0;
      this.nbrTotalLigneC = 0;
    this.fileText.forEach(async (elt, index, array) => {
      elt.etat = 1
      let eltFound = this.beneficiaires.find(element => elt.telephone == element.telephone)
      console.log(eltFound.telephone)
      let res: any = await this.http.get<any>(environment.baseUrl + environment.enrollerEmployeLot +"?telephone=" + eltFound.telephone+"&matricule=" + eltFound.matricule+"&type=" + this.programme, valuesys.httpAuthOptions()).subscribe(
        val => {
          if (val.code == 201) {
            this.toastr.success(val.msg,this.__("msg.success"));
            elt.etat = 2
            let montant = +elt.montant
            this.nbrTotalLigneC = this.nbrTotalLigneC + 1
            this.nbrTotalMontant = this.nbrTotalMontant + montant
            this.creditState = this.creditState + 1
            // this.close()
          } else {
            this.toastr.error(val.msg,this.__("msg.success"));
            elt.etat = 3
            this.nbrTotalLigneNC += this.nbrTotalLigneNC + 1
            this.creditState = this.creditState + 1
            this.getError = true
          }
        },
        response => {
        },
        () => {
          this.hasFinish =true
        }
      );
    })
    this.validated = true;
  }

  async controleNumber(elemeMontan: HTMLInputElement,type_:string) {
    if (type_ == 'm') {
      elemeMontan.value = elemeMontan.value.replace(/[^0-9]/gi, '');
      this.montant = elemeMontan.value;
      let res = await this.http.put<any>(environment.baseUrl + environment.calculFrais, {service_id:environment.CODE_RECHARGE_ESPECE, montant:this.montant} ,valuesys.httpAuthOptions()).toPromise();
      console.log(res)
      this.frais_calculate = res.data
    } else if (type_ == 'c'){
      elemeMontan.value = elemeMontan.value.replace(/[^0-9]/gi, '');
      this.code = elemeMontan.value;
    }
  }

  retour() {
    this.fileText = [];
    this.nbrTotalLigne = 0;
    this.nbrTotalLigneC = 0;
    this.nbrTotalLigneNC = 0;
    this.nbrTotalMontant = 0;
    // @ts-ignore
    document.getElementById('customFile').value=null;
    this.validated = false;
    this.uploaded = false;
    this.getError = false;
  }

  export(){
    let fileToExport = this.fileText.filter(elt => {
      return elt.etat == 3
    })
    let csvContent = "data:text/csv;charset=utf-8," ;
    fileToExport.forEach((rowArray)=> {
      let row = rowArray.telephone+";"+rowArray.montant
      csvContent += row + "\r\n";
    });

    var a = document.createElement("a"); //Create <a>
    a.href = csvContent; //Image Base64 Goes here
    a.download = "filename.csv"; //File name Here
    a.click();
    a.remove();

    //let encodedUri = encodeURI(csvContent);
    //window.open(encodedUri);
  }


    close(){

    }
}
