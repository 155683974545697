import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-employe-module',
  templateUrl: './employe-module.component.html',
  styleUrls: ['./employe-module.component.css']
})
export class EmployeModuleComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}
