import { Component, OnInit, TemplateRef } from '@angular/core';
import { Translatable } from "../../../../../Translatable";
import {DataTableDirective} from "angular-datatables";
import {HelperService} from "../../../../service/helper.service";
import {HttpClient} from "@angular/common/http";
import {valuesys} from "../../../../../../options";
import {environment} from "../../../../../../environments/environment";
import {carte, devise, pay_employe, tarif_frais} from "../../../../model/db";
import {BsModalRef, BsModalService} from "ngx-bootstrap";
declare const require: any;
const jsPDF = require('jspdf');
require('jspdf-autotable');
import html2canvas from 'html2canvas';
import {ToastrService} from "ngx-toastr";

@Component({
  selector: 'app-crediter-carte-lot',
  templateUrl: './crediter-carte-lot.component.html',
  styleUrls: ['./crediter-carte-lot.component.css']
})
export class CrediterCarteLotComponent extends Translatable implements OnInit {

  modalRef: BsModalRef;
  fileText: any[] = [];
  dtOptions: DataTables.Settings = {};
  dtOptions_Credit: DataTables.Settings = {};
  nbrTotalLigne:number = 0;
    motifs:string = '';
  nbrTotalLigneC:number = 0;
  nbrTotalLigneNC:number = 0;
  nbrTotalMontant:number = 0;
  public beneficiaire: pay_employe[];
  public beneficiaires:pay_employe[]=[];
  creditState:number = 0;
  getError:boolean = false;
  public titleModal: any;
  partie1: boolean = false;
  partie2: boolean = true;
  montant: any;
  code: any;
  idCodeValidation: any;
  frais_calculate: any ={
    "ht": 0,
    "tva":0,
    "ttc": 0,
    "res": 3
  }
  frais: tarif_frais;
  public numTransac: any;
  public recharge: any = {};
  validated: boolean = false;
  uploaded: boolean = false;
  public idCode_validation : string;

    public devises: devise[] = [];
    code_devise: string;

  constructor(
    private http: HttpClient,
    private helper: HelperService,
    private toastr: ToastrService,
    private modalService: BsModalService
  ) {
    super();
  }

  async ngOnInit() {
    //let resp = await this.http.get<any>(environment.baseUrl+ environment.carte ,valuesys.httpAuthOptions()).toPromise();
    //this.beneficiaires = resp.data
    //console.log(this.beneficiaires)
  }

  readUploadedFileAsText = (inputFile:File) => {
    let temporaryFileReader = new FileReader();
    return new Promise((resolve, reject) => {
      temporaryFileReader.onerror = () => {
        temporaryFileReader.abort();
        reject(new DOMException(this.__("bureau.echec_import_fichier")));
      };

      temporaryFileReader.onload = () => {
        resolve(temporaryFileReader.result);
      };
      temporaryFileReader.readAsText(inputFile);
    });
  };

    async fileChange(event) {

        this.devises = await this.helper.getSelectList(environment.baseUrl+ environment.devise,['nom', 'code'], 'code');


        let fileList: FileList = event.target.files;
            if (fileList.length > 0) {
                let file: File = fileList[0];
                let formData: FormData = new FormData();
                formData.append('file', file, file.name);
                let res:any = await this.http.post<any>(environment.baseUrl+ environment.uploadFileCreditCarte, formData, valuesys.httpUploadFileOptions()).toPromise();
                //console.log(res.data.Resultat);
                if (res.code == 200 ) {
                    this.uploaded = true;
                    this.beneficiaires = res.data;
                    let fileContents = await this.readUploadedFileAsText(file)
                    //console.log(fileContents.toString().split('\n'))
                    let fileTextString = fileContents.toString().split('\n').map((item)=>{
                        let eltFound:pay_employe = this.beneficiaires.find(element => item.split(";")[0] == element.telephone)
                        //console.log(eltFound)
                        if(!eltFound){
                            eltFound = new pay_employe()
                        }

                        let ob = {telephone:item.split(";")[0] ,nom:eltFound.nom + " " + eltFound.prenom, matricule:eltFound.matricule,montant:item.split(";")[1]}
                        return ob
                        //console.log(eltFound)
                    }).filter(_=>_.telephone != '');

                    this.fileText = fileTextString
                    this.nbrTotalLigne = this.fileText.length;
                    //console.log(this.fileText)
                    this.dtOptions_Credit = this.helper.dataTableOptionOffline();

                    //suppression des doublons
                    this.fileText = this.fileText.filter((set => f => !set.has(f.telephone) && set.add(f.telephone))(new Set));

                } else {
                    this.toastr.error(res.msg, this.__("msg.error"));
                }
            }
            else{
                this.toastr.error(this.__("msg.choice_file"), this.__("msg.error"));
            }

    }

  async handleUpload(event) {
    this.uploaded = true;
    let file = event.target.files[0];
    try {
      let fileContents = await this.readUploadedFileAsText(file)
      // console.log(fileContents.toString().split('\n'))
      let fileTextString = fileContents.toString().split('\r\n').map((item)=>{
        let telephone = `221${item.split(";")[0]}`
        let eltFound = this.beneficiaires.find(element => telephone == element.telephone)
        let beneficiaire = null
        if(eltFound) {
          beneficiaire = eltFound.prenom + " " + eltFound.nom
        }else{
          beneficiaire = "null"
        }
        let ob = {telephone:telephone,montant:item.split(";")[1], etat:0, nom: beneficiaire}
        return ob
      });

      this.fileText = fileTextString
      console.log(this.fileText)
      this.dtOptions_Credit = this.helper.dataTableOptionOffline();

      //suppression des doublons
      this.fileText = this.fileText.filter((set => f => !set.has(f.telephone) && set.add(f.telephone))(new Set));

    } catch (e) {
      console.warn(e.message)
    }
  }

  checkcarte(tel:string, textItem) {
    this.fileText = this.fileText.filter((set => f => !set.has(f.telephone) && set.add(f.telephone))(new Set));
    // let bb= this.bureaux_agence.filter((item)=>item.code == codeBureau);
    let res : pay_employe = this.beneficiaires.find((item:pay_employe) => item.telephone == tel);
    if(res){
      textItem.nom = res.prenom + ' ' + res.nom;
      return true ;
    }else {
      textItem.nom = ''
    }
    return false
  }

  deleteRow(text){
    this.fileText = this.fileText.filter((item)=>{
      return text != item
    })
    this.nbrTotalLigne = this.fileText.length
  }

  hasError(){
    let error=false
    if(this.fileText.length == 0) {
      return true
    };
    this.fileText.map((item)=>{
      if( this.beneficiaires.filter((b)=> b.telephone != item.telephone).length == this.beneficiaires.length){
        error = true
      }
    })
    return error;
  }

  close() {
    this.modalRef.hide();
  }

  async procedToExport(){
    let data = await  document.getElementById('contentToConvert');
    html2canvas(data).then(canvas => {

      // Few necessary setting options
      let imgWidth = 200;
      let pageHeight = 400;
      let imgHeight = canvas.height * imgWidth / canvas.width;
      let heightLeft = imgHeight;

      const contentDataURL = canvas.toDataURL('image/png')
      let pdf = new jsPDF('p', 'mm', 'a4'); // A4 size page of PDF
      let x = 0;
      let y = 0;
      let today = new Date().toLocaleDateString()
      pdf.addImage(contentDataURL, 'PNG', x, y, imgWidth, imgHeight)
      pdf.save(`Recap-credit-plusieurs-agences-${today}.pdf`); // Generated PDF
      this.toastr.success(this.__("exportPDF.export_ok"),this.__("msg.success"));
    })
  }

  async genererCode(template: TemplateRef<any>) {
    this.titleModal = this.__('employe.recharge_espece');
    this.modalRef = this.modalService.show(template,{ignoreBackdropClick:true});
    let res:any = await this.http.get<any>(environment.baseUrl+ environment.genererCodeCarte+"&code_devise="+this.code_devise,valuesys.httpAuthOptions()).toPromise();
    console.log(res.data.Resultat);
    this.idCode_validation = res.data.Resultat;
    if (res.code == 201 ) {

    } else {
      this.toastr.error(res.msg, this.__("msg.error"));
    }
  }

  async validatePartie2 () {
    if(this.motifs != '' && this.motifs != '') {
      this.fileText.forEach(async (elt, index, array) => {
        elt.etat = 1;
        let eltFound =await this.beneficiaires.find(element => elt.telephone == element.telephone);
        let res: any = await this.http.put<any>(environment.baseUrl+environment.rechargementCarteParLot,{tel:elt.telephone,montant:elt.montant, code: this.code, id_code: this.idCode_validation, motifs:this.motifs, nbligne:this.nbrTotalLigne, nbcredit:this.nbrTotalLigneC, code_devise: this.code_devise}, valuesys.httpAuthOptions()).subscribe(
          val => {
            if (val.code == 201) {
              this.toastr.success(val.msg,this.__("msg.success")) ;
              elt.etat = 2;
              let montant = +elt.montant;

              this.nbrTotalLigneC = this.nbrTotalLigneC + 1;
              this.nbrTotalMontant = this.nbrTotalMontant + montant;
              this.creditState = this.creditState + 1;
              this.close();
            } else {
              this.toastr.error(val.msg,this.__("msg.error")) ;
              elt.etat = 3;
              this.nbrTotalLigneNC += this.nbrTotalLigneNC + 1;
              this.creditState = this.creditState + 1;
              this.getError = true;
              this.close();
            }
          },
          response => {
          },
          () => {
          }
        );
      })
      this.validated = true;
      this.helper.getSoldeBureau();
    }
    else{
        this.toastr.error(this.__("msg.choice_programme"), this.__("msg.error"));
    }
  }

  async controleNumber(elemeMontan: HTMLInputElement,type_:string) {
    if (type_ == 'm') {
      elemeMontan.value = elemeMontan.value.replace(/[^0-9]/gi, '');
      this.montant = elemeMontan.value;
      let res = await this.http.put<any>(environment.baseUrl + environment.calculFrais, {service_id:environment.CODE_RECHARGE_ESPECE, montant:this.montant} ,valuesys.httpAuthOptions()).toPromise();
      console.log(res)
      this.frais_calculate = res.data
    } else if (type_ == 'c'){
      elemeMontan.value = elemeMontan.value.replace(/[^0-9]/gi, '');
      this.code = elemeMontan.value;
    }
  }

  retour() {
    this.fileText = [];
    this.nbrTotalLigne = 0;
    this.nbrTotalLigneC = 0;
    this.nbrTotalLigneNC = 0;
    this.nbrTotalMontant = 0;
    // @ts-ignore
    document.getElementById('customFile').value=null;
    this.validated = false;
    this.uploaded = false;
  }

  export(){
    let fileToExport = this.fileText.filter(elt => {
      return elt.etat == 3
    });
    console.log(fileToExport);
    let csvContent = "data:text/csv;charset=utf-8," ;
    csvContent += "Téléphone;montant" + "\r\n";
    fileToExport.forEach((rowArray)=> {
      let row = rowArray.telephone+";"+rowArray.montant
      csvContent += row + "\r\n";
    });

    let encodedUri = encodeURI(csvContent);
    window.open(encodedUri);
  }


}
