import { Component, OnInit, TemplateRef } from '@angular/core';
import {carte, devise, pay_employe, tarif_frais} from '../../../../model/db';
import { HttpClient } from '@angular/common/http';
import { environment } from 'src/environments/environment';
import { valuesys } from 'src/options';
import { Translatable } from "../../../../../Translatable";
import { ToastrService } from "ngx-toastr";
import {HelperService} from "../../../../service/helper.service";
declare const require: any;
const jsPDF = require('jspdf');
require('jspdf-autotable');
import html2canvas from 'html2canvas';
import { MaskNumberPipe } from "../../../../../mask-number.pipe";
import {BsModalRef, BsModalService} from "ngx-bootstrap";


@Component({
  selector: 'app-crediter-carte',
  templateUrl: './crediter-carte.component.html',
  styleUrls: ['./crediter-carte.component.css']
})
export class CrediterCarteComponent extends Translatable implements OnInit {
  btnSearch: boolean = false;
  showResult: boolean = false;
  modalRef: BsModalRef;
  public telephone: any;
  //public beneficiaires: wallet;
  public cartes: carte;
  private objetPhone: any;
  public titleModal: any;
  partie1: boolean = false;
  partie2: boolean = true;
  public montant: any;
  public recharge: any;
  public code_validation : number;
  public idCode_validation : string;
  code_retourne: any;
  fraisTtc: any;
  ht: any;
  tva: any;
    motifs: string;
  frais: tarif_frais;
  isValidated: boolean = false;
  isrecherche: boolean = true;
  public retrait: any = {};
  public numTransac: any;
    public devises: devise[] = [];
    code_devise: string;

  constructor(private http: HttpClient, private toastr: ToastrService, private modalService: BsModalService,private  helper:HelperService) {
    super();
  }

  ngOnInit() {
  }

  async validatePartie1() {


    let res1:any = await this.http.get<any>(environment.baseUrl+environment.genererCodeCarte+"&code_devise="+this.code_devise,valuesys.httpAuthOptions()).toPromise();
      if (res1.code== 201) {
          this.partie1 = true;
          this.partie2 = false;
          this.idCode_validation = res1.data.Resultat;
      }
      else{
          this.toastr.error(res1.msg,this.__("msg.error")) ;
      }


  }

  async validatePartie2() {
    let phone =  this.cartes.telephone;

    let res:any = await this.http.put<any>(environment.baseUrl+environment.validerCodeMultiple,{id:this.idCode_validation,code:this.code_validation,tel:phone,montant:this.montant,motifs:this.motifs,code_devise: this.code_devise},valuesys.httpAuthOptions()).toPromise();

    if (res.code== 201) {
      this.numTransac = res.data.numtransac;
      this.partie1 = true;

      let res3 = await this.http.get<any>(environment.baseUrl + environment.historiqueTransactionCarte +`?child=carte,paySociete,utilisateur&where=num_transac|e|${this.numTransac}`, valuesys.httpAuthOptions()).toPromise();
      //let res3 = await this.http.get<any>(environment.baseUrl+environment.transaction+`?child=carte,paySociete,utilisateur&where=num_transac|e|${this.numTransac}`, valuesys.httpAuthOptions()).toPromise();
      this.recharge = res3.data[0];
        this.helper.getSoldeBureau();
      this.toastr.success(res.msg,this.__("msg.success")) ;
      this.partie2 = true;
      this.motifs = '';
      this.close();
      this.isValidated = true
    }else {
      this.toastr.error(res.msg,this.__("msg.error")) ;
      this.partie2 = false;
    }
  }


  async search() {

    let res = await this.http.get<any>(environment.baseUrl+ environment.carte+`?telephone=${this.telephone}`,valuesys.httpAuthOptions()).toPromise();
    if (res.data.length > 0) {
      //this.toastr.success(res.msg,"Succès") ;
      this.cartes = res.data[0];
      this.showResult = true;
      this.telephone = null;
      this.btnSearch = true;

    }else {
      this.toastr.error(this.__("carte.no_number_for_carte")) ;
      this.showResult = false;
      this.btnSearch = false;
      this.cartes = new carte();
      this.telephone = ""
    }
  }


  async openModalRechargeEspeceWallet(template: TemplateRef<any>, currentCarte: carte = null) {

    this.devises = await this.helper.getSelectList(environment.baseUrl+ environment.devise,['nom', 'code'], 'code');
    //this.partie1=false;
    this.titleModal = this.__('wallet.recharge_espece');
    this.modalRef = this.modalService.show(template,{ignoreBackdropClick:true});
    this.partie1=false;
    this.frais = new tarif_frais();
    this.frais.ht = 0;
  }


  close() {
    this.modalRef.hide()
    this.partie1=true
    this.partie2=true
    this.showResult=false
    this.btnSearch=false;
    this.cartes = new carte();

  }

  onCountryChange(m){
    let lastDiall =  this.objetPhone .dialCode ;
    let newDiall = m.dialCode
    console.log(`LAST = ${lastDiall} ; NEW ${newDiall}`,m);
    this.element.value = this.element.value.replace(lastDiall+'',newDiall+'');
    this.telephone = this.element.value
    this.objetPhone = m ;
  }
  telInputObject(m){
    console.log('OBJET IN',m.s)
    this.objetPhone = m.s ;
  }
  element : any ;
  currenCode :string =environment.CURREN_CODE_COUNTRY;
  controle(element){
    this.element = element ;
    element.value = element.value.replace(/[^0-9]/gi,'');
    if((element.value || "").substr(0,this.objetPhone.dialCode.length).indexOf(this.objetPhone.dialCode.toString()) == -1){
      if(element.value.length <=  this.objetPhone.dialCode.length  && (element.value || "").substr( this.objetPhone.dialCode.length ).length < 1)
      {
        element.value =  this.objetPhone.dialCode;
      }
      else
      {
        if((element.value || "").substr(0,this.objetPhone.dialCode.length ).indexOf( this.objetPhone.dialCode.toString()) !== -1){
          element.value =  element.value;
        }
        else {
          element.value = this.objetPhone.dialCode +  element.value;
        }
      }
    }
    this.telephone =  element.value
  }
  async getFrais(){
    let res = await this.http.put<any>(environment.baseUrl + environment.calculFrais, {service_id:environment.CODE_RECHARGE_ESPECE, montant:this.montant} ,valuesys.httpAuthOptions()).toPromise();
    this.frais = res.data.Resultat;
  }

  async  controleNumber(elemeMontan: HTMLInputElement,type_:string) {
    if (type_ == 'm') {
      elemeMontan.value = elemeMontan.value.replace(/[^0-9]/gi, '');
    } else if (type_ == 'c'){
      elemeMontan.value = elemeMontan.value.replace(/[^0-9]/gi, '');
      this.code_retourne = elemeMontan.value;
    }
  }

  downloadRecu() {
    let data = document.getElementById('recu');
    html2canvas(data).then(canvas => {
      // Few necessary setting options
      let imgWidth = 200;
      let pageHeight = 400;
      let imgHeight = canvas.height * imgWidth / canvas.width;
      let heightLeft = imgHeight;

      const contentDataURL = canvas.toDataURL('image/png');
      let pdf = new jsPDF('p', 'mm', 'a4'); // A4 size page of PDF
      let x = 2;
      let y = 2;
      pdf.addImage(contentDataURL, 'PNG', x, y, imgWidth, imgHeight);
      pdf.save(`reçu_recharge_espèces_${this.numTransac}.pdf`); // Generated PDF
    })
  }

  retour() {
    this.btnSearch = false;
    this.isValidated = false;
    this.isrecherche = true;
    this.cartes = new carte();
    this.telephone = null ;
    this.montant = null;
    this.idCode_validation = null;
  }
  return(){
    this.cartes = new carte();
    this.telephone = ""
    this.showResult = false
    this.isrecherche = true
    this.btnSearch = false;
  }

    getDate(date){
        if(date != '' && date != null) {
            const tab = date.split(' ');

            const d = tab[0].split('-');
            if (tab.length == 2) {
                const dt = d[2] + "/" + d[1] + "/" + d[0] + " " + this.__("a") + " " + tab[1];
                return dt;
            }
            else {
                const dt = d[2] + "/" + d[1] + "/" + d[0];
                return dt;
            }
        }
        else return 'Pas de date';
    }
}
