import {Component, Input, OnInit} from '@angular/core';
import {Translatable} from "../../../../Translatable";
declare var $: any;
@Component({
  selector: 'app-carte-employe-side-bar',
  templateUrl: './carte-employe-side-bar.component.html',
  styleUrls: ['./carte-employe-side-bar.component.css']
})
export class CarteEmployeSideBarComponent extends Translatable implements OnInit {
  basesousmodule:string = "/app/modules/";
  constructor() {super() }

  async ngOnInit() {
    this.init() ;
  }

  init() {
    var d =  $(window),
      e = $("body"),
      f = $("#header"),
      g = $("#header .branding"),
      h = $("#leftmenu"),
      i = $("#controls"),
      j = $(".main_Wrapper"),
      k = $("#navigation"),
      l = $(".sparklineChart"),
      m = $(".slim-scroll"),
      n = $(".collapse-leftmenu"),
      o = $("#wrap"),
      p = $(".offcanvas-toggle"),
      q = k.find("ul").parent("li"),
      r = q.children("a"),
      s = k.children("li").not(q),
      t = s.children("a"),
      u = $(".color-themes .header-theme"),
      v = $(".color-themes .branding-theme"),
      w = $(".color-themes .leftmenu-theme"),
      x = $(".color-themes .color-theme"),
      y = $("#fixed-header"),
      z = $("#fixed-aside"),
      A = $(".toggle-right-leftmenu"),
      B = $(".pickDate"),
      C = ($(".boxs"), $(".boxs .boxs-toggle")),
      D = $(".boxs .boxs-refresh"),
      E = $(".boxs .boxs-fullscreen"),
      F = $(".boxs .boxs-close"),
      G = $(".easypiechart"),
      H = $(".toggle-class"),
      I = $(".colorpicker"),
      J = $(".touchspin"),
      K = $(".datepicker"),
      L = $(".animate-progress-bar"),
      M = $(".counter"),
      N = $(".splash");
    //alert('ok')
    if (q.length > 0) {
      q.addClass("dropdown");
      q.find("ul >.dropdown").addClass("submenu"), r.append('<i class="fa fa-plus"></i>'), r.on("click", function(a) {
        if (j.hasClass("leftmenu-sm") || j.hasClass("leftmenu-xs") || j.hasClass("hz-menu")) return !1;
        var b = $(this),
          c = b.parent("li"),
          d = $(".submenu.open");
        c.hasClass("submenu") || q.not(c).removeClass("open").find("ul").slideUp(), d.not(b.parents(".submenu")).removeClass("open").find("ul").slideUp(), c.toggleClass("open").find(">ul").stop().slideToggle(), a.preventDefault()
      }), q.on("mouseenter", function() {
        h.addClass("dropdown-open"), i.addClass("dropdown-open")
      }), q.on("mouseleave", function() {
        h.removeClass("dropdown-open"), i.removeClass("dropdown-open")
      }), t.on("click", function() {
        q.removeClass("open").find("ul").slideUp()
      });
      $(".dropdown>ul>.active").parent().css("display", "block")
    }
  }


}
