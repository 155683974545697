import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-wallet-employe',
  templateUrl: './wallet-employe.component.html',
  styleUrls: ['./wallet-employe.component.css']
})
export class WalletEmployeComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}
