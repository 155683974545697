import { Injectable } from '@angular/core';
import {CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot, UrlTree, Router} from '@angular/router';
import { Observable } from 'rxjs';
import {environment} from '../../../environments/environment';
import {AuthServiceService} from "./auth-service.service";
import {HelperService} from "./helper.service";

@Injectable({
  providedIn: 'root'
})
export class AuthGuard implements CanActivate {
  constructor( private router: Router, private auth: AuthServiceService,private helper:HelperService) {}
  async canActivate(
    next: ActivatedRouteSnapshot,
    state: RouterStateSnapshot) {

    let bool = await this.auth.isConnected(false);
    console.log('ETAT CONNECTER EGALE A ',bool);
    if(bool === 500){
      alert('Verifier votre connexion internet');
      return  false
    }
    if (bool !== 200) {
      this.router.navigate(['logout']);
      return  false ;
    }
    return true;
  }

}
