import {AfterViewInit, Component, OnDestroy, OnInit, TemplateRef, ViewChild} from '@angular/core';
import { transaction, pay_societe, pay_transaction, pay_employe } from './../../../../model/db';
import { environment } from 'src/environments/environment';
import { valuesys } from 'src/options';
import { wallet } from 'src/app/gestion/model/db';
import { Translatable } from 'src/app/Translatable';
import {HttpClient} from "@angular/common/http";
import {HelperService} from "../../../../service/helper.service";
import {DataTableDirective} from "angular-datatables";
import { DataTablesResponse } from 'src/app/gestion/model/DataTablesResponse';
import { ToastrService } from 'ngx-toastr';
import {Subject} from "rxjs";
import html2canvas from "html2canvas";
declare const require: any;
const jsPDF = require('jspdf');
require('jspdf-autotable');
import * as $ from "jquery";

@Component({
  selector: 'app-historique-transactions',
  templateUrl: './historique-transactions.component.html',
  styleUrls: ['./historique-transactions.component.css']
})
export class HistoriqueTransactionsComponent extends Translatable implements OnInit, AfterViewInit, OnDestroy {

  private now = (new Date()).toISOString().replace('T', ' ').substr(0, 10);
  private endTime = "23:59:59";
  date_debut: string = this.now;
  date_fin: string = this.now;
  showResult: boolean = false
  objetPhone: any;
  public transactions: transaction[] = [];
  public wallet: wallet = new wallet;
  public transaction: transaction
  dtOptions: DataTables.Settings = {};
  title: any;
  isValid: boolean;
  telephone: any ;
  walletId: any;
  init_=true;
  public transac: any = {};
  public numTransac: any;

  constructor(
    private http: HttpClient,private helper: HelperService, private toastr: ToastrService
  ) {
    super();
  }

  ngAfterViewInit(): void {
    this.dtTrigger.next();
  }

  ngOnDestroy(): void {
    this.dtTrigger.unsubscribe();
  }

  rerender(): void {
    this.dtElement.dtInstance.then((dtInstance: DataTables.Api) => {
      // Destroy the table first
      dtInstance.destroy();
      // Call the dtTrigger to rerender again
      this.dtTrigger.next();
    });
  }
  /*ddd*/
  @ViewChild(DataTableDirective, {static: false})
  dtElement: DataTableDirective;

  // @ts-ignore
  dtTrigger: Subject = new Subject();
  /*ddd*/

  ngOnInit() {
    this.initDatatable()
  }

  initDatatable() {
    this.isValid = false;
    this.title = this.__("wallet.historique");
    this.dtOptions = this.helper.dataTableOption();
    this.dtOptions.columns = [
      {data:"datetransac"},
      {data:"montant"},
      {data:"commission"},
      {data:'wallet_id'},
      {data:"service"},
      {data:"",searchable:false},
      {data:"",searchable:false}
    ];
    this.dtOptions.ajax =async (dataTablesParameters: any, callback) => {
      if(this.init_) {
        //this.init_=false
        this.http
          .get<DataTablesResponse>(
            environment.baseUrl + environment.payHistoriqueTransaction +`?child=paySociete&where=wallet_id|d|null &__order__=desc`, valuesys.httpAuthOptionsProcess($.param(dataTablesParameters))
          ).subscribe(resp => {
          this.transactions = resp.data
          console.log(this.transactions)
          callback({
            recordsTotal: resp.recordsTotal,
            recordsFiltered: resp.recordsFiltered,
            data: []
          });
        });
      }
      else if(this.telephone && this.objetPhone.dialCode != this.telephone ){
        let res = await this.http.get<any>(  environment.baseUrl+ environment.wallet+`?where=telephone|e|${this.telephone}`,valuesys.httpAuthOptions()).toPromise();
        let wallet  = res.data[0][0] ||  {}
        console.log(res.data[0][0])
        this.http
          .get<DataTablesResponse>(
            environment.baseUrl + environment.payHistoriqueTransaction +`?child=paySociete&where=wallet_id|e|${wallet.id || 0},date_transaction|se|${this.date_debut},date_transaction|ie|${this.date_fin + this.endTime}`, valuesys.httpAuthOptionsProcess($.param(dataTablesParameters))
          ).subscribe(resp => {
          this.transactions = resp.data
          console.log(this.transactions)

          callback({
            recordsTotal: resp.recordsTotal,
            recordsFiltered: resp.recordsFiltered,
            data: []
          });
        });
      }else if(this.objetPhone.dialCode == this.telephone || !this.telephone ) {
        this.http
          .get<DataTablesResponse>(
            environment.baseUrl + environment.payHistoriqueTransaction+`?child=paySociete&where=wallet_id|d|null,date_transaction|se|${this.date_debut},date_transaction|ie|${this.date_fin + this.endTime}`, valuesys.httpAuthOptionsProcess($.param(dataTablesParameters))
          ).subscribe(resp => {
          this.transactions = resp.data
          console.log(this.transactions)
          callback({
            recordsTotal: resp.recordsTotal,
            recordsFiltered: resp.recordsFiltered,
            data: []
          });
        });
      }
    }
  }

  async openRecu(currentTransaction:transaction =null,openRecuBtn) {
    openRecuBtn.disabled = true;
    let res1 = await this.http.get<any>(environment.baseUrl + environment.payHistoriqueTransaction +`?child=wallet,paySociete,utilisateur&where=id|e|`+ currentTransaction.id, valuesys.httpAuthOptions()).toPromise();
    this.transac = res1.data[0];
    this.numTransac = res1.data[0].numtransac;
    console.log(this.transac)
    openRecuBtn.disabled = false;
    this.showResult = true;
  }

  retour() {
    this.showResult = false;
  }

  downloadRecu() {
    let data = document.getElementById('recu');
    html2canvas(data).then(canvas => {
      // Few necessary setting options
      let imgWidth = 200;
      let pageHeight = 400;
      let imgHeight = canvas.height * imgWidth / canvas.width;
      let heightLeft = imgHeight;

      const contentDataURL = canvas.toDataURL('image/png')
      let pdf = new jsPDF('p', 'mm', 'a4'); // A4 size page of PDF
      let x = 2;
      let y = 2;
      pdf.addImage(contentDataURL, 'PNG', x, y, imgWidth, imgHeight)
      pdf.save(`TransactionNum${this.numTransac}.pdf`); // Generated PDF
    })
  }


  onCountryChange(m) {
    let lastDiall =  this.objetPhone .dialCode ;
    let newDiall = m.dialCode
    console.log(`LAST = ${lastDiall} ; NEW ${newDiall}`,m);
    this.element.value = this.element.value.replace(lastDiall+'',newDiall+'');
    this.telephone = this.element.value
    this.objetPhone = m;
  }
  telInputObject(m) {
    console.log('OBJET IN', m.s)
    this.objetPhone = m.s;
  }
  element : any ;
  currenCode :string =environment.CURREN_CODE_COUNTRY;
  controle(element) {
    this.element = element ;
    element.value = element.value.replace(/[^0-9]/gi, '');
    if ((element.value || "").substr(0, this.objetPhone.dialCode.length).indexOf(this.objetPhone.dialCode.toString()) == -1) {
      if (element.value.length <= this.objetPhone.dialCode.length && (element.value || "").substr(this.objetPhone.dialCode.length).length < 1) {
        element.value = this.objetPhone.dialCode;
      }
      else {
        if ((element.value || "").substr(0, this.objetPhone.dialCode.length).indexOf(this.objetPhone.dialCode.toString()) !== -1) {
          element.value = element.value;
        }
        else {
          element.value = this.objetPhone.dialCode + element.value;
        }
      }
    }
    this.wallet.telephone = element.value
  }

  reset() {
    this.init_=true
    this.telephone = null;
    // @ts-ignore
    document.getElementById("periode").value = null;
    this.date_debut = this.now;
    this.date_fin = this.now;
    this.rerender()
  }

  formatDate(date) {
    var d = date.split('/');
    return [d[2], d[0], d[1]].join('-');
  }

  async search() {
    this.init_ = false;
    let periode = $('#periode').val();
    if(periode != ''){
      let tab = periode.toString().split(' - ');
      this.date_debut = this.formatDate(tab[0]);
      this.date_fin = this.formatDate(tab[1]);
    }
    this.rerender()
  }
    getDate(date){
        if(date != '' && date != null) {
            const tab = date.split(' ');

            const d = tab[0].split('-');
            if (tab.length == 2) {
                const dt = d[2] + "/" + d[1] + "/" + d[0] + " " + this.__("a") + " " + tab[1];
                return dt;
            }
            else {
                const dt = d[2] + "/" + d[1] + "/" + d[0];
                return dt;
            }
        }
        else return this.__('msg.no_date');
    }

}
