import { Component, OnInit } from '@angular/core';
import {Translatable} from "../../../../Translatable";
import {environment} from "../../../../../environments/environment";
import {AuthServiceService} from "../../../service/auth-service.service";

@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.css']
})
export class HomeComponent extends Translatable implements OnInit {

    public user_ : any = {info:{},law:{}};
    stateC: number;
    stateW: number;

    constructor(private auth : AuthServiceService) {
    super();
  }

  async ngOnInit() {
      this.user_ = await this.auth.getLoginUser(true);
      this.stateC = this.user_.info.stateWNC.carte || 1;
      this.stateW = this.user_.info.stateWNC.wallet || 1;
  }

}
