import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'maskNumber'
})
export class MaskNumberPipe implements PipeTransform {

  transform(number: string): string {
    if (number){
      const visibleDigits = 2;
      let maskedSection = number.slice(0, -visibleDigits);
      let visibleSection = number.slice(-visibleDigits);
      return maskedSection.replace(/./g, '*') + visibleSection;
    }

  }

}
